import { useState } from "react";
import clsx from "clsx";
import { Card, GoogleMap, Common, Icon } from "@/components";
import { EyeMapDropdown } from "./EyeMapDropdown";
import { DestinationItem } from "./DestinationItem";

interface Props {
  onCloseDetail: () => void;
}

export const OptimizedReviewDetail = ({ onCloseDetail }: Props) => {
  const [isHideDetails, setHideDetails] = useState(false);

  return (
    <Card
      title={
        <div className="flex items-center">
          <button className="mr-2" onClick={onCloseDetail}>
            <Icon.Back size="14" className="fill-neutral-80" />
          </button>
          <span>Optimized Route South to West - Route AB</span>
          <div>
            <div className="w-1.5 h-1.5 rounded-full bg-neutral-30 mx-3" />
          </div>
        </div>
      }
      middleHeaderContent={
        <div className="flex items-center">
          <div className="text-neutral-70 text-sm">
            <div className="text-xs text-neutral-50">Start time</div>
            06:40AM
          </div>
          <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
          <div className="text-neutral-70 text-sm">
            <div className="text-xs text-neutral-50">End time</div>
            04:30PM
          </div>

          <div className="text-neutral-70 text-sm ml-10">
            <div className="text-xs text-neutral-50">Driver break period</div>
            12:00AM - 1:30PM
          </div>
        </div>
      }
      rightHeaderContent={
        <div>
          <Common.Checkbox ipSize="md" checked label="Apply for the future" />
        </div>
      }
    >
      <div className="flex p-4 min-h-[50vh]">
        <div className={clsx("w-[26.5rem] mr-4", isHideDetails && "hidden")}>
          <div className="flex items-center mb-4">
            <div className="text-hd5 font-semibold flex-1">Destinations</div>
            <Common.Button outline onClick={() => setHideDetails(true)}>
              Hide details
            </Common.Button>
          </div>
          <div className="max-h-[calc(100vh-25rem)] overflow-y-auto">
            <DestinationItem isFirst />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
            <DestinationItem />
            <DestinationItem className="bg-neutral-5" />
          </div>
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          {isHideDetails && (
            <div className="absolute top-3 left-3 z-10">
              <Common.Button outline onClick={() => setHideDetails(false)}>
                show details
              </Common.Button>
            </div>
          )}

          <div className="ralative z-0 w-full h-full">
            <GoogleMap />
          </div>
          <div className="absolute bottom-3 right-3 z-10">
            <EyeMapDropdown />
          </div>
        </div>
      </div>
    </Card>
  );
};
