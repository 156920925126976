import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import {
  deliveryManifest,
  manifest,
  manifestLine,
  storageAddress,
} from "../../models/DeliveryManifest";
import { sortManifestLine } from "../../utils";

const API_PATH = "Delivery/";

export const fetchAllManifestByDateAsync = createAsyncThunk(
  "fetchAllManifestByDate",
  async (data: { date: string; isFetchETA: boolean }) => {
    console.log("get manifests");
    return axios
      .post(`${API_PATH}GetManifests`, { date: data.date })
      .then((res) => {
        if ((window as any).getETAByManifestIdController) {
          (window as any).getETAByManifestIdController.abort();
        }

        if (res.data.errorMessage) {
          return res.data;
        }

        const manifests = res.data.data.manifests.map((manifest: manifest) => {
          return sortManifestLine(manifest);
        });

        return {
          ...res.data.data,
          manifests,
        };
      });
  }
);

export const fetchStorageAddressAsync = createAsyncThunk(
  "fetchStorageAddress",
  async () => {
    return axios.get(`${API_PATH}GetStorageAddress`).then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }

      return res.data.data;
    });
  }
);

const initialState = {
  deliveryManifest: {} as deliveryManifest,
  loading: false,
  loaded: false,
  storageAddress: {} as storageAddress,
  storageAddressLoading: false,
  storageAddressLoaded: false,
};

const manifestSlice = createSlice({
  name: "manifest",
  initialState,
  reducers: {
    clearManifest: (state) => {
      state.deliveryManifest = {} as deliveryManifest;
      state.loading = false;
      state.loaded = false;
    },
    moveRoute: (state, action: PayloadAction<any>) => {
      const { manifestTarget, position, manifestLineMoving } = action.payload;
      const addManifestLines = cloneDeep(manifestTarget.manifestLines);
      addManifestLines.splice(position - 1, 0, manifestLineMoving);
      addManifestLines.map((item: manifestLine, index: number) => {
        item.position = index;
        return item;
      });

      state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
        (manifest) => {
          if (manifest.id === manifestTarget.id) {
            manifest.manifestLines = addManifestLines;
          }
          return manifest;
        }
      );
    },
    replaceManifestsUpdatedETAs: (state, action: PayloadAction<any>) => {
      state.deliveryManifest.manifests = action.payload;
    },
    updateManifests: (state, action: PayloadAction<any>) => {
      const { manifests, unallocatedDocumentCount } = action.payload;
      if (manifests.length) {
        state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
          (manifest) =>
            manifests.find(
              (newManifest: manifest) =>
                newManifest && newManifest.id === manifest.id
            ) || manifest
        );
      }
      if (
        unallocatedDocumentCount &&
        state.deliveryManifest.unallocatedDocumentCount >= 0
      ) {
        state.deliveryManifest.unallocatedDocumentCount =
          state.deliveryManifest.unallocatedDocumentCount +
          unallocatedDocumentCount;
      }
    },
    updateETAIntoManifestItem: (state, action: PayloadAction<any>) => {
      state.deliveryManifest.manifests = state.deliveryManifest.manifests.map(
        (manifest: manifest) =>
          manifest.id === action.payload.id ? action.payload : manifest
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllManifestByDateAsync.pending, (state) => {
        state.loading = true;
        state.loaded = false;
        state.deliveryManifest = {} as deliveryManifest;
      })
      .addCase(fetchAllManifestByDateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.deliveryManifest = action.payload;
      })
      .addCase(fetchStorageAddressAsync.pending, (state) => {
        state.storageAddressLoading = true;
      })
      .addCase(fetchStorageAddressAsync.fulfilled, (state, action) => {
        state.storageAddressLoading = false;
        state.storageAddressLoaded = true;
        state.storageAddress = action.payload;
      });
  },
});

export const {
  moveRoute,
  clearManifest,
  updateManifests,
  updateETAIntoManifestItem,
  replaceManifestsUpdatedETAs,
} = manifestSlice.actions;
export default manifestSlice;
