import { useState, createRef } from "react";
import isEmpty from "lodash/isEmpty";
import { ModalInform } from "@/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { manifest, manifestLine } from "../models/DeliveryManifest";
import { DestinationDetailEditingItem } from "./DestinationDetailEditingItem";
import { ModalChangePosition } from "./ModalChangePosition";
import { checkCompletedDocumentPosition, reorderManifestLines } from "../utils";
import { DepotItem } from "./DepotItem";
import { DeliveryLineStatusValue } from "@/configuration/globalVariable";
import { SnackbarUtils } from "@/utils";

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (
  // eslint-disable-next-line
  isDraggingOver: any
) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
});

interface Props {
  data: manifest;
  // ETALineData: manifestDetailETA;
  manifestETALoading: boolean;
  onListChange?: (manifest: manifest) => void;
  onUnallocate: (manifestLine?: manifestLine) => void;
  // onMoveItem: (
  //   manifestTarget?: manifest,
  //   position?: string,
  //   manifestLine?: manifestLine
  // ) => void;
}

export const DeliveryRouteDetailEditingList = ({
  data,
  manifestETALoading,
  onListChange,
  onUnallocate,
}: Props) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const debotRef = createRef<HTMLDivElement>();
  const [isOpenUnallocateModal, setOpenUnallocateModal] =
    useState<manifestLine>();
  const [positionChangedToHighlight, setPositionChangedToHighlight] =
    useState<number>();
  const [manifestLineChangePositionModal, setManifestLineChangePositionModal] =
    useState<manifestLine>();
  const [placeholderProps, setPlaceholderProps] = useState<any>({});

  const handleDragUpdate = (event: any) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginTop = parseFloat(style.marginTop);
        return total + curr.clientHeight + marginTop;
      }, 0) +
      debotRef.current?.clientHeight +
      10;
    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const getDraggedDom = (draggableId: any) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleDragStart = (event: any) => {
    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginTop = parseFloat(style.marginTop);
          return total + curr.clientHeight + marginTop;
        }, 0) +
      debotRef.current?.clientHeight +
      10;

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  const onDragEnd = (result: any) => {
    setPlaceholderProps({});
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    handleReorder(
      data.manifestLines,
      result.source.index,
      result.destination.index
    );
  };

  const handleReorder = (
    list: manifestLine[],
    startIndex: number,
    endIndex: number
  ) => {
    const items = reorderManifestLines(list, startIndex, endIndex);
    setPositionChangedToHighlight(endIndex);
    onListChange &&
      onListChange({
        ...data,
        manifestLines: items,
      });
  };

  const handleUnllocate = () => {
    onUnallocate(isOpenUnallocateModal);
    setOpenUnallocateModal({} as manifestLine);
  };

  const handleChangePosition = (position: number) => {
    if (manifestLineChangePositionModal) {
      if (checkCompletedDocumentPosition(data, position)) {
        return SnackbarUtils.error(
          "This position is delivered. Please enter another position number."
        );
      }
      handleReorder(
        data.manifestLines,
        manifestLineChangePositionModal?.position,
        position
      );
      setManifestLineChangePositionModal({} as manifestLine);
    }
  };

  return (
    <>
      <DepotItem ref={debotRef} />
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="px-4"
            >
              {data.manifestLines.map((item, index) => {
                const isCompleted =
                  item.deliveryStatus === DeliveryLineStatusValue.COMPLETED;
                return isCompleted ? (
                  <DestinationDetailEditingItem
                    data={item}
                    onChangePositionClick={setManifestLineChangePositionModal}
                    onUnallocateClick={setOpenUnallocateModal}
                    manifestETALoading={manifestETALoading}
                    positionChangedToHighlight={positionChangedToHighlight}
                    isCompleted={isCompleted}
                    routeStatus={data.status}
                    className="mt-2"
                  />
                ) : (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={isCompleted}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="mt-2"
                      >
                        <DestinationDetailEditingItem
                          data={item}
                          onChangePositionClick={
                            setManifestLineChangePositionModal
                          }
                          onUnallocateClick={setOpenUnallocateModal}
                          manifestETALoading={manifestETALoading}
                          positionChangedToHighlight={
                            positionChangedToHighlight
                          }
                          isCompleted={isCompleted}
                          routeStatus={data.status}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <div
                  className="placeholder border rounded border-dashed border-neutral-30 border-spacing-1 absolute bg-neutral-20"
                  style={{
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth,
                  }}
                />
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {data?.driver?.returnToDepot && <DepotItem className="mt-2" />}
      <ModalInform
        open={Boolean(isOpenUnallocateModal?.id)}
        onClose={() => setOpenUnallocateModal({} as manifestLine)}
        onConfirm={handleUnllocate}
        confirmBtnText="Unallocate"
        title={`Are you sure you want to unallocate order #${isOpenUnallocateModal?.document?.saleDocumentNumber}?`}
        type="error"
      />
      <ModalChangePosition
        open={Boolean(manifestLineChangePositionModal?.id)}
        data={manifestLineChangePositionModal}
        onClose={() => setManifestLineChangePositionModal({} as manifestLine)}
        onSubmit={handleChangePosition}
      />
    </>
  );
};
