import { useCallback } from "react";
import { useAppSelector } from "@/sharedStore/hooks";

export const useGetRouteDetailById = () => {
  const manifests = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.manifests
  );
  return useCallback(
    (id: string) => {
      if (manifests) {
        return manifests.find((value) => value.id === id);
      }
    },
    [manifests]
  );
};

export const useGetRouteDetailByRouteId = () => {
  const manifests = useAppSelector(
    (state) => state.deliverySlice.deliveryManifest.manifests
  );
  return useCallback(
    (id: string) => {
      if (manifests) {
        return manifests.find((value) => value.routeId === id);
      }
    },
    [manifests]
  );
};
