// import { Icon } from "@/components";
import React, { FC, ButtonHTMLAttributes } from "react";
import clsx from "clsx";
import { Icon } from "@/components/Icons";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  btSize?: "lg" | "md" | "sm";
  rounded?: boolean;
  outline?: boolean;
  color?: "red" | "blue" | "gray" | "green" | "yellow" | "transparent";
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  iconType?: "stroke" | "fill";
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: FC<Props> = ({
  btSize = "lg",
  rounded = false,
  outline = false,
  color = "blue",
  className = "",
  disabled = false,
  isLoading = false,
  children = null,
  iconType = "fill",
  iconLeft: IconLeft = undefined,
  iconRight: IconRight = undefined,
  ...rest
}) => {
  const handleSize = () => {
    if (btSize === "lg" || !btSize) {
      return children ? "px-4 h-9" : "w-9 h-9";
    } else if (btSize === "md") {
      return children ? "px-3 h-8" : "w-8 h-8";
    } else if (btSize === "sm") {
      return children ? "px-3 h-7 text-sm" : "w-7 h-7 text-sm";
    }
  };

  const handleColor = () => {
    if (disabled) {
      return color === "transparent"
        ? "cursor-default border-transparent text-neutral-40"
        : "cursor-default bg-neutral-10 border-neutral-20 text-neutral-40";
    }

    if (color === "transparent") {
      return "text-neutral-50 border-transparent hover:text-neutral-60";
    }

    if (color === "gray") {
      return "text-neutral-50 border-neutral-20 hover:text-neutral-60 hover:border-neutral-50";
    }

    if (outline) {
      return clsx(
        color === "blue" &&
          "bg-white text-blue border-neutral-20 hover:text-blue-dark hover:border-blue-dark",
        color === "red" &&
          "bg-white text-red border-neutral-20 hover:text-red-dark hover:border-red-dark",
        color === "green" &&
          "bg-white text-green border-neutral-20 hover:text-green-dark hover:border-green-dark",
        color === "yellow" &&
          "bg-white text-yellow border-neutral-20 hover:text-yellow-dark hover:border-yellow-dark"
      );
    } else {
      return clsx(
        color === "blue" &&
          "border-blue text-white bg-blue hover:border-blue-dark hover:bg-blue-dark",
        color === "red" &&
          "border-red text-white bg-red hover:border-red-dark hover:bg-red-dark",
        color === "green" &&
          "border-green text-white bg-green hover:border-green-dark hover:bg-green-dark",
        color === "yellow" &&
          "border-yellow text-white bg-yellow hover:border-yellow-dark hover:bg-yellow-dark"
      );
    }
  };

  const handleIconColor = () => {
    if (iconType === "stroke") {
      if (disabled) {
        return "fill-none stroke-neutral-40";
      }
      if (outline) {
        return clsx(
          "fill-none",
          color === "blue" && "stroke-blue group-hover:stroke-blue-dark",
          color === "red" && "stroke-red group-hover:stroke-red-dark",
          color === "green" && "stroke-green group-hover:stroke-green-dark",
          color === "yellow" && "stroke-yellow group-hover:stroke-yellow-dark",
          ["gray", "transparent"].includes(color) &&
            "stroke-neutral-50 group-hover:stroke-neutral-60"
        );
      } else {
        return clsx(
          "fill-none",
          ["gray", "transparent"].includes(color)
            ? "stroke-neutral-50 group-hover:stroke-neutral-60"
            : "stroke-white"
        );
      }
    } else {
      if (disabled) {
        return "fill-neutral-40";
      }
      if (outline) {
        return clsx(
          color === "blue" && "fill-blue group-hover:fill-blue-dark",
          color === "red" && "fill-red group-hover:fill-red-dark",
          color === "green" && "fill-green group-hover:fill-green-dark",
          color === "yellow" && "fill-yellow group-hover:fill-yellow-dark",
          ["gray", "transparent"].includes(color) &&
            "fill-neutral-50 group-hover:fill-neutral-60"
        );
      } else {
        return clsx(
          ["gray", "transparent"].includes(color)
            ? "fill-neutral-50 group-hover:fill-neutral-60"
            : "fill-white"
        );
      }
    }
  };

  return (
    <>
      <button
        {...rest}
        type="button"
        className={clsx(
          "inline-flex items-center justify-center border font-medium group",
          handleSize(),
          handleColor(),
          rounded ? "rounded-full" : "rounded",
          className
        )}
        disabled={disabled}
      >
        {IconLeft && (
          <div>
            {isLoading ? (
              <Icon.Loading
                size="16"
                className={clsx(children && "mr-2", handleIconColor())}
              />
            ) : (
              <IconLeft
                size="16"
                className={clsx(children && "mr-2", handleIconColor())}
              />
            )}
          </div>
        )}
        {isLoading && !IconLeft && !IconRight && (
          <Icon.Loading
            size="16"
            className={clsx(children && "mr-2", handleIconColor())}
          />
        )}
        {children}
        {IconRight && (
          <div>
            {isLoading ? (
              <Icon.Loading
                size="16"
                className={clsx(children && "ml-2", handleIconColor())}
              />
            ) : (
              <IconRight
                size="16"
                className={clsx(children && "ml-2", handleIconColor())}
              />
            )}
          </div>
        )}
      </button>
    </>
  );
};

export default Button;
