import { RootState } from "@/sharedStore/store";

export const manifestsSelector = (state: RootState) =>
  state.deliverySlice.deliveryManifest.manifests;
export const unallocatedDocumentCountSelector = (state: RootState) =>
  state.deliverySlice.deliveryManifest.unallocatedDocumentCount;
export const deliveryManifest = (state: RootState) =>
  state.deliverySlice.deliveryManifest;
export const deliveryManifestLoading = (state: RootState) =>
  state.deliverySlice.loading;
export const deliveryManifestLoaded = (state: RootState) =>
  state.deliverySlice.loaded;

export const storageAddressSelector = (state: RootState) =>
  state.deliverySlice.storageAddress;
export const storageAddressLoadedSelector = (state: RootState) =>
  state.deliverySlice.storageAddressLoaded;
export const storageAddressLoadingSelector = (state: RootState) =>
  state.deliverySlice.storageAddressLoading;
