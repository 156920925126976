import { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import union from "lodash/union";
import {
  Card,
  Common,
  CustomScrollbar,
  Icon,
  ModalContent,
  ModalInform,
  Pagination,
  Table,
  Td,
  Th,
} from "@/components";
import { DatePicker } from "@/components/common/Form/Datepicker";
import { LIMIT_SCHEDULE_PER_PAGE } from "../configVariable";
import { documentViolate } from "../models";
import {
  useGetAffectedDocuments,
  useSaveAffectedDocuments,
} from "../services/schedule";
import { tab } from "@/models";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { ModalChangeDateViolate } from "./ModalChangeDateViolate";
import { currencyFormatter, SnackbarUtils } from "@/utils";
import clsx from "clsx";
// import { loremIpsum } from "lorem-ipsum";

const status = {
  Unresolved: 0,
  KeepDate: 1,
  MoveDate: 2,
  Remove: 3,
};

const TABS = [
  {
    status: status.Unresolved,
    name: "Unresolved",
    current: true,
    number: 0,
  },
  {
    status: status.KeepDate,
    name: "Keep original dates",
    current: true,
    number: 0,
  },
  {
    status: status.MoveDate,
    name: "Moved dates",
    current: true,
    number: 0,
  },
  {
    status: status.Remove,
    name: "Cancelled",
    current: true,
    number: 0,
  },
];

// function getRandomInt(max: number) {
//   return Math.floor(Math.random() * max);
// }

// function getRandomArbitrary(min: number, max: number) {
//   return Math.floor(Math.random() * (max - min) + min);
// }

// const createList = (num = 200) => {
//   const arr = [] as documentViolate[];
//   for (let i = 0; i < num; i++) {
//     const date = `${getRandomInt(32)}/${getRandomInt(13)}/${getRandomArbitrary(
//       2020,
//       2023
//     )}`;
//     arr.push({
//       saleDocumentNumber: i + loremIpsum({ units: "words", count: 1 }),
//       purchaseDocumentNumber: loremIpsum({ units: "words", count: 1 }),
//       customerAccountId: i.toString(),
//       customerAccountName: loremIpsum({ units: "words", count: 3 }),
//       documentId: i.toString(),
//       totalAmount: getRandomArbitrary(100, 500),
//       orderDeliveryDate: date,
//       deliveryDate: date,
//       despatchDate: date,
//       status: 0,
//     });
//   }
//   return arr;
// };

interface Props {
  open: boolean;
  scheduleId: string;
  onClose: (isRecounterViolateDocument?: boolean) => void;
  violateOrder?: number;
}

export const ModalViolate = ({
  violateOrder,
  scheduleId,
  open,
  onClose,
  ...rest
}: Props) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(LIMIT_SCHEDULE_PER_PAGE[0]);
  const [sort, setSort] = useState<{
    [Key: string]: "ASC" | "DESC" | undefined;
  }>({});

  const getAffectedDocuments = useGetAffectedDocuments();
  const saveAffectedDocuments = useSaveAffectedDocuments();
  const [tabs, setTabs] = useState<any>([...TABS]);
  const [unresolveCheckIds, setUnresolveCheckIds] = useState<string[]>([]);
  const [anotherCheckIds, setAnotherCheckIds] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyWord] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<Date>();
  const [documents, setDocuments] = useState<documentViolate[]>([]);
  const [activeTab, setActiveTab] = useState<any>(tabs[0]);
  const [docsChangeDate, setDocsChangeDate] = useState<documentViolate[]>([]);
  const [leaveConfirm, setLeaveConfirm] = useState<boolean>(false);

  const { isLoading: isListFetching } = getAffectedDocuments;
  const { isLoading: isSavingDocuments } = saveAffectedDocuments;
  useEffect(() => {
    if (open) {
      getAffectedDocuments.mutate(scheduleId, {
        onSuccess: (res) => {
          handleCountStatus(res);
        },
      });
      // const arr = createList(200);
      // handleCountStatus(arr);
    } else {
      // Reset all state
      ReactTooltip.hide();
      setPageSize(LIMIT_SCHEDULE_PER_PAGE[0]);
      setPage(1);
      setActiveTab(tabs[0]);
      setUnresolveCheckIds([]);
      setAnotherCheckIds([]);
      setDocuments([]);
      setSearchKeyWord("");
      setDateFilter(undefined);
      setTabs([...TABS]);
    }
  }, [open]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleActiveTab = useCallback((t: string | number) => {
    setActiveTab(tabs.find((tab: tab) => tab.name === t));
  }, []);

  const handleCountStatus = (orderList: documentViolate[]) => {
    setTabs(
      tabs.map((t: tab) => {
        return {
          ...t,
          number: orderList.filter((od) => od.status === t.status).length,
        };
      })
    );
    setDocuments(orderList);
  };

  const handleSearchKeyWord = useCallback((text: any) => {
    setSearchKeyWord(text);
  }, []);

  const handleChangeDateFilter = useCallback((date: Date) => {
    setDateFilter(date);
  }, []);

  const handleOnChangeCheckRow = useCallback(
    (idAdd: string, isUnresolved: boolean) => {
      isUnresolved
        ? setUnresolveCheckIds((ids) =>
            ids.includes(idAdd)
              ? ids.filter((id) => id != idAdd)
              : ids.concat([idAdd])
          )
        : setAnotherCheckIds((ids) =>
            ids.includes(idAdd)
              ? ids.filter((id) => id != idAdd)
              : ids.concat([idAdd])
          );
    },
    []
  );

  const handleUnCheckAllRow = useCallback(
    (idsRemove: string[], isUnresolved: boolean) => {
      isUnresolved
        ? setUnresolveCheckIds((ids) =>
            ids.filter((id) => !idsRemove.includes(id))
          )
        : setAnotherCheckIds((ids) =>
            ids.filter((id) => !idsRemove.includes(id))
          );
    },
    []
  );

  const handleCheckAllRow = useCallback(
    (idsAdd: string[], isUnresolved: boolean) => {
      isUnresolved
        ? setUnresolveCheckIds((ids) => union(ids, idsAdd))
        : setAnotherCheckIds((ids) => union(ids, idsAdd));
    },
    []
  );

  const isCheckedAll = (ids: string[], unresolved: boolean) =>
    unresolved
      ? Boolean(ids.length) &&
        unresolveCheckIds.filter((id) => ids.includes(id)).length === ids.length
      : Boolean(ids.length) &&
        anotherCheckIds.filter((id) => ids.includes(id)).length === ids.length;

  const handleChangeStatusDocBulkAction = (
    docIds: string[],
    statusTarget: number
  ) => {
    handleChangeStatusDoc(docIds, statusTarget);
    activeTab.status === status.Unresolved
      ? setUnresolveCheckIds([])
      : setAnotherCheckIds([]);
  };

  const handleChangeStatusDoc = (docIds: string[], statusTarget: number) => {
    const activeUnresolvedTab = activeTab.status === status.Unresolved;
    const newDocs = documents.map((doc) => {
      if (docIds.includes(doc.documentId))
        return {
          ...doc,
          status: activeUnresolvedTab ? statusTarget : status.Unresolved,
        };
      return doc;
    });
    if (activeUnresolvedTab) {
      unresolveCheckIds?.length &&
        setUnresolveCheckIds((ids) => ids.filter((id) => !docIds.includes(id)));
    } else {
      anotherCheckIds?.length &&
        setAnotherCheckIds((ids) => ids.filter((id) => !docIds.includes(id)));
    }
    handleCountStatus(newDocs);
    ReactTooltip.hide();
  };

  const handleMoveDocuments = (data: any) => {
    const newDocs = documents.map((doc) => {
      if (docsChangeDate.find((d) => d.documentId === doc.documentId))
        return {
          ...doc,
          IsErrorOrWarning: data.IsErrorOrWarning,
          deliveryDate: moment(data.deliveryDate).format(
            TIME_FORMAT.GENERAL_DISPLAY
          ),
          despatchDate: moment(data.despatchDate).format(
            TIME_FORMAT.GENERAL_DISPLAY
          ),
          status: status.MoveDate,
        };
      return doc;
    });
    unresolveCheckIds?.length &&
      setUnresolveCheckIds((ids) =>
        ids.filter((id) => !docsChangeDate.find((d) => d.documentId === id))
      );
    handleCountStatus(newDocs);
    ReactTooltip.hide();
    handleCloseChangeDateModal();
  };

  const handleCloseChangeDateModal = () => {
    setDocsChangeDate([]);
  };

  const handleOpenChangeDateModal = (id?: string) => {
    setDocsChangeDate(
      documents.filter((doc) =>
        id ? doc.documentId === id : unresolveCheckIds.includes(doc.documentId)
      )
    );
  };

  const handleClose = () => {
    isEditing ? setLeaveConfirm(true) : onClose();
  };

  const handleSaveEdit = () => {
    const documentsToSave = documents
      .filter((doc) => doc.status != 0)
      .map((doc) => {
        if (doc.status === status.MoveDate) {
          const newDoc = {
            ...doc,
            orderDeliveryDate: doc.deliveryDate,
          };
          return newDoc;
        }
        return doc;
      });
    saveAffectedDocuments.mutate(documentsToSave, {
      onSuccess: () => {
        SnackbarUtils.success(
          `<b>${documentsToSave.length}</b> document(s) processed successfully.`
        );
        onClose(true);
      },
    });
  };

  const results = useMemo(() => {
    const list = documents?.length
      ? documents.filter((od: documentViolate) => {
          if (
            od.customerAccountName
              .toUpperCase()
              .indexOf(searchKeyword.toUpperCase()) > -1 &&
            od.status === activeTab.status &&
            (dateFilter
              ? od.orderDeliveryDate ===
                moment(dateFilter).format(TIME_FORMAT.GENERAL_DISPLAY)
              : true)
          )
            return true;
          return false;
        })
      : [];
    const column = Object.keys(sort)[0];
    if (column && list.length) {
      list.sort((a: any, b: any) => {
        const valueA = ["total", "status"].includes(column)
          ? a[column]
          : column === "orderDeliveryDate"
          ? moment(a[column], TIME_FORMAT.GENERAL_DISPLAY).valueOf()
          : a[column]?.toLowerCase();
        const valueB = ["total", "status"].includes(column)
          ? b[column]
          : column === "orderDeliveryDate"
          ? moment(b[column], TIME_FORMAT.GENERAL_DISPLAY).valueOf()
          : b[column]?.toLowerCase();
        if (sort[column] === "ASC") {
          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
        } else {
          if (valueA < valueB) return 1;
          if (valueA > valueB) return -1;
        }
        return 0;
      });
    }
    return list;
  }, [documents, searchKeyword, activeTab, dateFilter, sort]);

  const resultsDisplay = useMemo(() => {
    const orders = results.slice((page - 1) * pageSize, page * pageSize);
    return orders;
  }, [results, page, pageSize]);

  const totalPage = useMemo(() => {
    return Math.ceil(results.length / pageSize);
  }, [results, page, pageSize]);

  const isActiveUnresolvedTab = activeTab.status === status.Unresolved;
  const rowsId = useMemo(
    () => resultsDisplay.map((r) => r.documentId),
    [resultsDisplay]
  );
  const isEditing = tabs[0]?.number !== documents?.length;

  // handle pagination/sort
  const handleChangePage = (p: number) => {
    setPage(p);
  };
  const handleChangePageSize = (ps: number) => {
    setPageSize(ps);
    setPage(1);
  };

  const handleSort = (column: string) => {
    setSort((s) =>
      !s[column] || s[column] === "DESC"
        ? { [column]: "ASC" }
        : { [column]: "DESC" }
    );
  };

  useEffect(() => {
    if (page != 1 && !resultsDisplay.length) {
      handleChangePage(page - 1);
    }
  }, [results]);
  // end handle pagination/sort

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditing]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "There are some changes have been made. Are you sure to cancel all changes?";

    if (isEditing) {
      e.returnValue = message;
    } else {
      delete e.returnValue;
    }
    return message;
  };

  return (
    <>
      <ModalContent
        {...rest}
        open={open}
        onClose={handleClose}
        panelClassWidth="max-w-[68.75rem]"
      >
        <Card
          title={`Schedule change confirmation`}
          titleClassName="md:text-hd4"
          footerContent={
            <div className="w-full border-t border-neutral-20 py-4 px-6 flex items-center justify-end">
              <Common.Button
                color="transparent"
                onClick={handleClose}
                disabled={isSavingDocuments}
              >
                Cancel
              </Common.Button>
              <Common.Button
                className="ml-2"
                isLoading={isSavingDocuments}
                disabled={!isEditing || isSavingDocuments}
                onClick={handleSaveEdit}
              >
                Confirm
              </Common.Button>
            </div>
          }
        >
          <div className="px-6 py-3">
            <Common.Alert
              alertType="warning"
              className="items-center"
              iconRender={
                <Icon.WarningCircle size="30" className="fill-status-warning" />
              }
            >
              There are <b>{violateOrder}</b> future orders that violate the
              delivery schedule. You can cancel an order or move to other dates
              before saving the schedule.
            </Common.Alert>
            <div className="flex justify-between items-center border-b border-neutral-20">
              <div className="font-semibold text-hd5">{activeTab.name}</div>
              <div className="mb-[-1px]">
                <Common.TabUnderline
                  data={tabs}
                  activeTab={activeTab.name}
                  setActiveTab={handleActiveTab}
                  wrapClassName="mt-1"
                />
              </div>
            </div>
            <div className="py-3">
              <div className="flex items-center mb-2">
                <Common.Input
                  wrapClassName="2xl:lg:w-[26.375rem] mr-3"
                  className="bg-neutral-5"
                  iconRight={Icon.Search}
                  iconType="stroke"
                  placeholder="Type to filter by customer name, ..."
                  value={searchKeyword}
                  onChange={(e: any) => handleSearchKeyWord(e.target.value)}
                  onClear={() => handleSearchKeyWord("")}
                />
                <div className="w-[9.375rem] ml-3">
                  <DatePicker
                    selected={dateFilter as Date}
                    onChange={handleChangeDateFilter}
                    placeholder="Select date"
                  />
                </div>
                <div className="ml-auto">
                  {isActiveUnresolvedTab &&
                    Boolean(unresolveCheckIds.length) && (
                      <>
                        <strong className="text-blue">
                          {unresolveCheckIds.length}
                        </strong>{" "}
                        selected
                      </>
                    )}
                  {!isActiveUnresolvedTab &&
                    Boolean(anotherCheckIds.length) && (
                      <>
                        <strong className="text-blue">
                          {anotherCheckIds.length}
                        </strong>{" "}
                        selected
                      </>
                    )}
                </div>
                <div className="ml-2">
                  <Common.DropDowns
                    disabled={isSavingDocuments}
                    buttonRender={
                      <div
                        className={clsx(
                          "inline-flex items-center justify-center font-medium group  rounded border px-2 py-1",
                          isSavingDocuments
                            ? "text-neutral-40 border-neutral-20"
                            : "text-blue border-blue hover:border-blue-dark"
                        )}
                      >
                        Bulk action
                        <Icon.ArrowDown
                          size="10"
                          className={clsx(
                            "ml-3",
                            isSavingDocuments ? "fill-neutral-40" : "fill-blue"
                          )}
                        />
                      </div>
                    }
                    listRender={
                      <>
                        {isActiveUnresolvedTab && (
                          <>
                            <Common.DropdownItem
                              disabled={!unresolveCheckIds.length}
                              onClick={() => handleOpenChangeDateModal()}
                            >
                              <Icon.MoveTo className="fill-neutral-50 mr-2" />
                              Move to other dates
                            </Common.DropdownItem>
                            <Common.DropdownItem
                              disabled={!unresolveCheckIds.length}
                              onClick={() =>
                                handleChangeStatusDocBulkAction(
                                  unresolveCheckIds,
                                  status.KeepDate
                                )
                              }
                            >
                              <Icon.ScheduleCheck className="fill-neutral-50 mr-2" />
                              Keep original dates
                            </Common.DropdownItem>
                          </>
                        )}
                        <Common.DropdownItem
                          disabled={
                            !(isActiveUnresolvedTab
                              ? unresolveCheckIds.length
                              : anotherCheckIds.length)
                          }
                          onClick={() =>
                            handleChangeStatusDocBulkAction(
                              isActiveUnresolvedTab
                                ? unresolveCheckIds
                                : anotherCheckIds,
                              status.Remove
                            )
                          }
                        >
                          <Icon.Remove className="fill-neutral-50 mr-2" />
                          {isActiveUnresolvedTab
                            ? "Cancel order"
                            : "Back to unresolved list"}
                        </Common.DropdownItem>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="h-[280px] border border-neutral-20">
                <CustomScrollbar>
                  <Table className="relative w-full" data-id={scheduleId}>
                    <thead className="text-neutral-50">
                      <tr>
                        <Th>
                          <Common.Checkbox
                            ipSize="md"
                            name="checkall"
                            checked={isCheckedAll(
                              rowsId,
                              isActiveUnresolvedTab
                            )}
                            onChange={(e) => {
                              e.target.checked
                                ? handleCheckAllRow(
                                    rowsId,
                                    isActiveUnresolvedTab
                                  )
                                : handleUnCheckAllRow(
                                    rowsId,
                                    isActiveUnresolvedTab
                                  );
                            }}
                            disabled={isSavingDocuments}
                          />
                        </Th>
                        <Th
                          onSortClick={() => handleSort("saleDocumentNumber")}
                          sort={sort?.saleDocumentNumber}
                        >
                          <span className="whitespace-nowrap">SALE #</span>
                        </Th>
                        <Th
                          onSortClick={() =>
                            handleSort("purchaseDocumentNumber")
                          }
                          sort={sort?.purchaseDocumentNumber}
                        >
                          <span className="whitespace-nowrap">Purchase #</span>
                        </Th>
                        <Th
                          onSortClick={() => handleSort("customerAccountName")}
                          sort={sort?.customerAccountName}
                        >
                          <span className="whitespace-nowrap">Customer</span>
                        </Th>
                        <Th
                          onSortClick={() => handleSort("total")}
                          sort={sort?.total}
                          className="text-right"
                        >
                          <span className="whitespace-nowrap">
                            Total Amount
                          </span>
                        </Th>
                        <Th
                          onSortClick={() => handleSort("orderDeliveryDate")}
                          sort={sort.orderDeliveryDate}
                          className="text-right"
                        >
                          <span className="whitespace-nowrap">
                            Delivery Date
                          </span>
                        </Th>
                        <Th className="w-[8.25rem]"></Th>
                      </tr>
                    </thead>
                    {Boolean(results.length) && !isListFetching && (
                      <tbody className="bg-white">
                        {resultsDisplay.map((doc: documentViolate) => (
                          <tr
                            key={doc.documentId}
                            data-documentid={doc.documentId}
                          >
                            <Td className="border-t border-neutral-20">
                              <Common.Checkbox
                                ipSize="md"
                                checked={
                                  isActiveUnresolvedTab
                                    ? unresolveCheckIds.includes(doc.documentId)
                                    : anotherCheckIds.includes(doc.documentId)
                                }
                                onChange={() =>
                                  handleOnChangeCheckRow(
                                    doc.documentId,
                                    isActiveUnresolvedTab
                                  )
                                }
                                disabled={isSavingDocuments}
                              />
                            </Td>
                            <Td className="border-t border-neutral-20">
                              <span>{doc.saleDocumentNumber}</span>
                            </Td>
                            <Td className="border-t border-neutral-20">
                              <span>{doc.purchaseDocumentNumber}</span>
                            </Td>
                            <Td className="!whitespace-normal border-t border-neutral-20">
                              <span className="max-w-[12.5rem]">
                                {doc.customerAccountName}
                              </span>
                            </Td>
                            <Td className="border-t border-neutral-20 text-right">
                              <span>
                                {doc?.total &&
                                  currencyFormatter.format(doc.total)}
                              </span>
                            </Td>
                            <Td className="border-t border-neutral-20 text-right">
                              <span>
                                {activeTab.status === status.MoveDate
                                  ? doc.deliveryDate
                                  : doc.orderDeliveryDate}
                              </span>
                            </Td>
                            <Td className="w-[8.25rem] border-t border-neutral-20">
                              <div className="w-[6.75rem] text-right">
                                {isActiveUnresolvedTab && (
                                  <>
                                    <span data-tip="Move to another date">
                                      <Common.Button
                                        disabled={isSavingDocuments}
                                        color="transparent"
                                        className="px-2"
                                        onClick={() =>
                                          handleOpenChangeDateModal(
                                            doc.documentId
                                          )
                                        }
                                      >
                                        <Icon.MoveTo className="fill-blue-dark-50" />
                                      </Common.Button>
                                    </span>
                                    <span data-tip="Keep original dates">
                                      <Common.Button
                                        disabled={isSavingDocuments}
                                        color="transparent"
                                        className="px-2"
                                        onClick={() =>
                                          handleChangeStatusDoc(
                                            [doc.documentId],
                                            status.KeepDate
                                          )
                                        }
                                      >
                                        <Icon.ScheduleCheck className="fill-green" />
                                      </Common.Button>
                                    </span>
                                  </>
                                )}
                                <span
                                  data-tip={
                                    isActiveUnresolvedTab
                                      ? "Cancel order"
                                      : "Back to unresolved list"
                                  }
                                >
                                  <Common.Button
                                    disabled={isSavingDocuments}
                                    color="transparent"
                                    className="px-2"
                                    onClick={() =>
                                      handleChangeStatusDoc(
                                        [doc.documentId],
                                        status.Remove
                                      )
                                    }
                                  >
                                    {isActiveUnresolvedTab ? (
                                      <Icon.Remove className="fill-red" />
                                    ) : (
                                      <Icon.Refresh />
                                    )}
                                  </Common.Button>
                                </span>
                              </div>
                            </Td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </Table>
                  <div>
                    {isListFetching ? (
                      <Common.NoData
                        iconRender={Icon.Loading}
                        title="Loading Unresolved orders...."
                      />
                    ) : (
                      !results.length && (
                        <>
                          {searchKeyword || dateFilter ? (
                            <Common.NoData
                              title={`No ${activeTab.name} orders found.`}
                            />
                          ) : (
                            <Common.NoData
                              title={`${
                                isActiveUnresolvedTab ? "Great! " : ""
                              }No ${activeTab.name} orders`}
                            />
                          )}
                        </>
                      )
                    )}
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex justify-between items-center w-100 p-4 border border-neutral-20 border-t-0">
                <div className="flex justify-between items-center">
                  <Common.DropDowns
                    buttonRender={
                      <div className="inline-flex items-center justify-center font-medium group text-blue hover:border-blue-dark rounded border px-4 py-2 border-neutral-30">
                        Show {pageSize}
                        <Icon.AngleDown
                          size="10"
                          className="stroke-blue ml-3"
                        />
                      </div>
                    }
                    listRender={
                      <div className="py-0">
                        {LIMIT_SCHEDULE_PER_PAGE.map((item) => {
                          return (
                            <Common.DropdownItem
                              key={item}
                              onClick={() => handleChangePageSize(item)}
                            >
                              Show {item}
                            </Common.DropdownItem>
                          );
                        })}
                      </div>
                    }
                    listClassName="mb-12 bottom-0"
                  />
                  <div className="color-neutral-60 font-normal ml-3">
                    of{" "}
                    <span className="color-neutral font-semibold">
                      {results.length}
                    </span>{" "}
                    orders
                  </div>
                </div>
                {Boolean(results.length) && (
                  <Pagination
                    pageNumber={page}
                    pageSize={pageSize}
                    total={results.length}
                    totalPages={totalPage}
                    pagerCenterPoint={1}
                    startPage={1}
                    onPageClick={handleChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </Card>
      </ModalContent>
      <ModalChangeDateViolate
        data={docsChangeDate}
        onClose={handleCloseChangeDateModal}
        scheduleId={scheduleId}
        onMove={handleMoveDocuments}
      />
      <ModalInform
        open={leaveConfirm}
        title="Are you sure you want to leave?"
        description="If you leave now, all changes will not be saved."
        onClose={() => {}}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button
              color="red"
              outline
              onClick={() => {
                onClose();
                setLeaveConfirm(false);
              }}
            >
              Leave now
            </Common.Button>
            <Common.Button
              className="ml-4"
              onClick={() => setLeaveConfirm(false)}
            >
              Stay on this page
            </Common.Button>
          </div>
        }
      />
    </>
  );
};
