import { useState } from "react";
import clsx from "clsx";
import { Common, Icon } from "@/components";

interface Props {
  isOptimizing?: boolean;
  isFirst?: boolean;
}

export const OptimizingItem = ({ isFirst, isOptimizing }: Props) => {
  const [isChecked, setChecked] = useState(false);
  return (
    <div
      className={clsx(
        "flex px-4 py-2.5 border border-neutral-10 rounded group bg-white",
        !isFirst && "mt-2"
      )}
    >
      <div>
        <Common.Checkbox
          checked
          disabled
          onChange={() => setChecked(!isChecked)}
        />
      </div>
      <div className="ml-4 text-sm text-neutral-70">
        <div className="flex items-center mb-2">
          <Common.DotColor />
          <div
            className="font-semibold text-neutral-80 ml-2 mr-3"
            data-tip="South to West - Route AB"
          >
            South to West - Route AB
          </div>

          {isOptimizing ? (
            <div>
              <Icon.Loading size="16" />
            </div>
          ) : (
            <div data-tip="Optimized">
              <Icon.CheckedCircle size="16" />
            </div>
          )}
        </div>
        <div className="flex items-center mb-2">
          <Icon.Driving size="16" className="fill-neutral-40" />
          <div className=" ml-1.5">#3251 - That Armore Cheese </div>
          <Icon.Clock size="12" className="fill-neutral-40 ml-4 mr-1.5" />
          <div>10:00 AM</div>
        </div>
        <div className="flex items-center">
          <Icon.Driver size="16" className="fill-neutral-40" />
          <div className=" ml-1.5">Arlene McCoy </div>
          <Icon.DeliveryTime
            size="12"
            className="fill-neutral-40 ml-4 mr-1.5"
          />
          <div>9 / 12 orders</div>
        </div>
      </div>
      <div className="flex items-center ml-auto opacity-0 group-hover:opacity-100">
        <div
          className="flex items-center justify-center bg-blue-5 w-6 h-6 rounded-full cursor-pointer"
          data-tip="View details"
        >
          <Icon.Next size="12" className="fill-neutral-40" />
        </div>
      </div>
    </div>
  );
};
