import React, { useRef, useState, useEffect, useMemo, memo } from "react";
import clsx from "clsx";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Marker from "@/components/Googlemap/Marker";
import PolyLine from "@/components/Googlemap/Polyline";
import {
  manifest,
  // manifestDetailETA,
  manifestLine,
  // manifestLineETA,
  storageAddress,
} from "../models";
import { GOOGLE_API_KEY, GOOGLE_MAP_ID } from "@/configuration/globalVariable";
import { EyeMapDropdown } from "@/components/Googlemap/EyeMapDropdown";
import { LoadingOverlay } from "@/components";
import { filterDupplicateManifestLine } from "../utils";
// import { EyeMapDropdown } from "./EyeMapDropdown";

const Map = ({ children, className, center, zoom, allMarkerlatlng }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          mapId: GOOGLE_MAP_ID,
        })
      );
    }
    // eslint-disable-next-line
  }, [ref, map]);

  useEffect(() => {
    map?.setOptions({ center, zoom });
    // eslint-disable-next-line
  }, [center, zoom]);

  useEffect(() => {
    if (map) {
      if (allMarkerlatlng && allMarkerlatlng.length > 1) {
        const latlngbounds = new google.maps.LatLngBounds();
        for (let i = 0; i < allMarkerlatlng.length; i++) {
          latlngbounds.extend(allMarkerlatlng[i]);
        }
        map.fitBounds(latlngbounds);
      } else {
        map.setOptions({ center, zoom });
      }
    }
    // eslint-disable-next-line
  }, [allMarkerlatlng, map]);

  return (
    <>
      <div ref={ref} className={clsx("w-full h-full", className)} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map } as any);
        }
      })}
    </>
  );
};

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Map is loading.....
        </div>
      );
    case Status.FAILURE:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Map loaded error.
        </div>
      );
    default:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Loading.....
        </div>
      );
  }
};

interface Props {
  storageAddress: storageAddress;
  manifests: manifest[];
  routesLoading?: boolean;
}

export const GoogleMap = memo(
  ({ storageAddress, manifests, routesLoading }: Props) => {
    const [routesDisplay, setRoutesDisplay] = useState<manifest[]>(
      manifests || []
    );

    const center = useMemo(() => {
      return {
        lat: storageAddress?.latitude || -25.344,
        lng: storageAddress?.longitude || 131.031,
      };
      // eslint-disable-next-line
    }, [storageAddress]);
    const zoom = useMemo(
      () => (storageAddress?.latitude ? 11 : 4.6),
      [storageAddress]
    );

    const ParseETAPolyline = (polyline: string) => {
      const polylines = JSON.parse(polyline)[0];

      if (polylines) {
        const decode = google.maps.geometry.encoding.decodePath(polylines);
        const listposition = [];

        for (let c = 0; c < decode.length; c++) {
          const coords = new google.maps.LatLng(
            decode[c].lat() / 10,
            decode[c].lng() / 10
          );
          listposition.push(coords);
        }
        return listposition;
      }
    };

    // const getManifest = (id: string) =>
    //   manifests && (manifests.find((mnf) => mnf.id === id) as manifest);

    const isRenderLine = useMemo(
      () => Boolean(routesDisplay && routesDisplay.length),
      [routesDisplay]
    );

    const allMarkerlatlng = useMemo(() => {
      if (manifests?.length) {
        const latlng = [
          new google.maps.LatLng(
            storageAddress?.latitude || 0,
            storageAddress?.longitude || 0
          ),
        ];
        manifests.map((manifest: manifest) => {
          if (
            manifest &&
            manifest.manifestLines &&
            manifest.manifestLines.length &&
            manifest.polylines
          ) {
            manifest.manifestLines.map((manifestLine: manifestLine) => {
              return manifestLine?.latitude
                ? latlng.push(
                    new google.maps.LatLng(
                      manifestLine?.latitude || 0,
                      manifestLine?.longitude || 0
                    )
                  )
                : null;
            });
          }
          return manifest;
        });
        return latlng;
      }
      // eslint-disable-next-line
    }, [manifests]);

    const handleShowHideRoute = (route: manifest) => {
      setRoutesDisplay(
        routesDisplay.some((rd) => rd.manifestId === route.id)
          ? routesDisplay.filter((rd) => rd.manifestId !== route.id)
          : routesDisplay.concat(
              manifests.filter(
                (mfETA: manifest) => mfETA.manifestId === route.id
              )
            )
      );
    };

    useEffect(() => {
      const newManifests = manifests
        ? manifests.map((mf) => {
            if (mf) {
              return {
                ...mf,
                manifestLine: filterDupplicateManifestLine(mf?.manifestLines),
              };
            }
            return mf;
          })
        : [];
      setRoutesDisplay(newManifests);
    }, [manifests]);

    return GOOGLE_API_KEY ? (
      <div className="ralative w-full h-full">
        {routesLoading && <LoadingOverlay />}
        <Wrapper
          apiKey={GOOGLE_API_KEY}
          render={render}
          version="beta"
          libraries={["geometry", "marker"]}
        >
          <Map center={center} zoom={zoom} allMarkerlatlng={allMarkerlatlng}>
            {storageAddress?.latitude && (
              <Marker
                position={{
                  lat: storageAddress?.latitude || 0,
                  lng: storageAddress?.longitude || 0,
                }}
                data={storageAddress}
                isStore
              />
            )}

            {isRenderLine && routesDisplay.length
              ? routesDisplay.map(
                  (manifestLineItem: manifest) =>
                    manifestLineItem.polylines &&
                    manifestLineItem.manifestLines.map((mnfl) => {
                      return mnfl?.latitude && mnfl?.longitude ? (
                        <Marker
                          key={mnfl.id}
                          position={{
                            lat: mnfl?.latitude || 0,
                            lng: mnfl?.longitude || 0,
                          }}
                          data={mnfl}
                          manifest={manifestLineItem}
                        />
                      ) : (
                        <></>
                      );
                    })
                )
              : null}

            {isRenderLine &&
              routesDisplay.length &&
              routesDisplay.map(
                (manifestLineItem) =>
                  manifestLineItem.polylines && (
                    <PolyLine
                      key={manifestLineItem.manifestId}
                      path={ParseETAPolyline(manifestLineItem.polylines)}
                      strokeColor={manifestLineItem?.route.colour}
                    />
                  )
              )}
          </Map>
        </Wrapper>
        <div className="absolute bottom-3 left-3 z-10">
          <EyeMapDropdown
            manifests={manifests}
            onShowHideClick={handleShowHideRoute}
            routesActiveShow={routesDisplay}
          />
        </div>
      </div>
    ) : null;
  }
);
