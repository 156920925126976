export const DOMAIN_URL = process.env.REACT_APP_DOMAIN;
export const STATIC_URL = process.env.REACT_APP_STATIC_DOMAIN;
export const API_ENDPOINT = DOMAIN_URL + "/webapi/api/";
// export const API_ENDPOINT = DOMAIN_URL;
export const AUTH = {
  API_KEY: process.env.REACT_APP_LOGIN_API_KEY,
  CLIENT_INFO: process.env.REACT_APP_LOGIN_CLIENT_INFO,
  ACCESS_TOKEN_KEY: "access_token",
};
export const BUILD_MODE = process.env.REACT_APP_BUILD_MODE;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const GOOGLE_MAP_ID = process.env.REACT_APP_GOOGLE_MAP_ID;

export const ROLE = {
  ADMIN: "00000000-0000-0004-0001-000000000000",
  USERS: "00000000-0000-0004-0002-000000000000",
  DRIVERS: "00000000-0000-0004-0005-000000000000",
  PURCHASING: "00000000-0000-0004-0006-000000000000",
  SALE: "00000000-0000-0004-0007-000000000000",
  PICKER: "00000000-0000-0004-0008-000000000000",
};

export const ENV = {
  DEV: "DEV",
  INT: "INT",
  STG: "STG",
  PROD: "PROD",
  DEMO: "DEMO",
};

export const TIME_FORMAT = {
  ETA_TIME: "hh:mmA",
  NOTI: "MMM, L [at] LT",
  REQUEST: "DD/MM/YYYY",
  INPUT_DISPLAY: "dd/MM/yyyy",
  GENERAL_DISPLAY: "DD/MM/YYYY",
};

export const USER_VIEW = {
  CUSTOMER: 2,
  SUPPLIER: 3,
};
// interface Path {
//   [key: string]: Path | string;
//   _: string;
// }
// interface Paths{
//   [key: string]: Path | string
// }

export const PATH = {
  STARTED: "get-started",

  AUTH: "auth",
  SIGN_UP: "sign-up",
  SIGN_IN: "sign-in",
  SIGN_OUT: "sign-out",

  HOME: "/",
  ENTER_ORDER: "enter-order",
  TRANSFER: "transfer",
  REDIRECT: "redirect",

  CUSTOMER_MANAGEMENT: "customer-management",
  MY_CUSTOMER: "my-customer",
  EDIT_MY_CUSTOMER: "edit-my-customer",
  BROWSE_CUSTOMER: "browse-customer",

  SALES_ORDERS: "sales-orders",
  ORDERS: "orders",
  INVOICES: "invoices",
  QUOTES: "quotes",

  SCHEDULE_PLANNER: {
    _: "schedule-planner",
    SCHEDULE_LIST: "delivery-schedule-planner-list",
    SCHEDULE_DETAIL: "delivery-schedule-planner-detail",
    SCHEDULE_EDIT: "delivery-schedule-planner-edit",
    SCHEDULE_ASSIGNED_LIST: "assign-list",
  },

  DELIVERY: "delivery",
  DELIVERY_MANIFESTS: "delivery-manifests",
  EDIT_ROUTES: "edit-routes",
  ROUTE_DETAILS: "route-details",
  ALLOCATE: "allocate",
  SCHEDULE_DETAIL: "delivery-schedule-planner-detail",
  SCHEDULE_EDIT: "delivery-schedule-planner-edit",

  REPORT: {
    _: "report",
    PICKING: {
      _: "picking",
      GROUPBY: {
        _: "groupby",
        PICKINGSTATUS: "pickingstatus",
        PRODUCTS: "products",
        DOCUMENTS: "documents",
      },
    },
  },

  IFRAME: {
    _: "iframe",
    PANTRY_LIST: {
      _: "pantry-list",
    },
  },

  PANTRY_LIST: "pantry-list",

  APP_STORYBOOK: "app-storybook",
  COMMON_BUTTON: "button-components",
  COMMON_ICON: "icon-components",
  COMMON_FORM: "form-components",
  COMMON_TOOLTIP: "tooltip-components",
  COMMON_LABEL: "label-components",
  NOTISTACK: "notistack-components",
  ALERTS: "alert-components",
  LISTBOX: "listbox-components",
  TIMELINE: "timeline-components",
  TABLE: "table-components",
};

export const DocumentTypeId = {
  ORDER: 2,
  INVOICE: 3,
};

export const DeliveryStatusValue = {
  PENDING: 0,
  DELIVERING: 1,
  STOPPED: 2,
  COMPLETED: 3,
  UNAVALAIBLE: 4,
};

export const DeliveryLineStatusValue = {
  PENDING: 0,
  DELIVERING: 2,
  COMPLETED: 4,
};

export const DeliveryStatus = {
  // yellow darken
  [DeliveryStatusValue.PENDING]: {
    label: "Pending",
    colourClass: "yellow",
    colour: "#FFE8CC",
  },
  // Blue
  [DeliveryStatusValue.DELIVERING]: {
    label: "Delivering",
    colourClass: "blue",
    colour: "#CCE4FF",
  },
  // red
  [DeliveryStatusValue.STOPPED]: {
    label: "Stopped",
    colourClass: "red",
    colour: "#FEDAD8",
  },
  // green
  [DeliveryStatusValue.COMPLETED]: {
    label: "Completed",
    colourClass: "green",
    colour: "#D7F4E6",
  },
  // neutral
  [DeliveryStatusValue.UNAVALAIBLE]: {
    label: "UnavailableSupplierDelivery",
    colourClass: "",
    colour: "#E2E8F0",
  },
};

export const DeliveryLineStatus = {
  // yellow darken
  [DeliveryLineStatusValue.PENDING]: {
    label: "Pending",
    colourClass: "yellow",
    colour: "#FFE8CC",
  },
  // Blue
  [DeliveryLineStatusValue.DELIVERING]: {
    label: "Delivering",
    colourClass: "blue",
    colour: "#CCE4FF",
  },
  // green
  [DeliveryLineStatusValue.COMPLETED]: {
    label: "Completed",
    colourClass: "green",
    colour: "#D7F4E6",
  },
  // 1    Pending Not delivery yet
  // 2    Delivering  In the current delivering manifest
  // 3    Delivered   Has been delivered
  // 4    Complete    Delivered and not in the current delivering manifes
};

// Pending, stopped status
export const CanModifyRouteByStatus = [
  DeliveryStatusValue.PENDING,
  DeliveryStatusValue.STOPPED,
];

// Picking Status
export enum EPickingStatus {
  ToPick,
  Picked,
  Substitute,
  NotAvailable,
  BackOrder,
}
