import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import moment from "moment";
import { Common, Icon, RedirectToOUI, CustomScrollbar } from "@/components";
import { DeliveryItem } from "./DeliveryItem";
import {
  PATH,
  CanModifyRouteByStatus,
  TIME_FORMAT,
} from "@/configuration/globalVariable";

import { useAppSelector } from "@/sharedStore/hooks";
import {
  deliveryManifestLoading,
  deliveryManifestLoaded,
} from "../providers/deliveryManifest/selector";
import { manifest } from "../models/DeliveryManifest";

interface Props {
  isOptimized?: boolean;
  routesChecked: string[];
  searchKeyword: string;
  date: any;
  unallocatedDocumentCount?: number;
  onCheckRoute: (routeId: string) => void;
  unallocateChecked: boolean;
  isETAsLoading?: boolean;
  onCheckUnallocate: (unallocateChecked: boolean) => void;
  manifests: manifest[];
}

export const DeliveryList = ({
  isOptimized,
  isETAsLoading,
  routesChecked,
  searchKeyword,
  date,
  unallocatedDocumentCount,
  unallocateChecked,
  onCheckUnallocate,
  onCheckRoute,
  manifests,
}: Props) => {
  const navigate = useNavigate();
  const loading = useAppSelector(deliveryManifestLoading);
  const loaded = useAppSelector(deliveryManifestLoaded);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [manifests, loaded]);

  useEffect(() => {
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  const handleOpenAllocate = () => {
    navigate(`/${PATH.DELIVERY}/${PATH.EDIT_ROUTES}`, {
      state: {
        unallocate: true,
        deliveryDate: date,
        routesActive: [{}],
        routesUnActive: [],
      },
    });
  };

  const isRoutes = manifests && manifests.length;
  const isToday = moment().isSame(date, "day");

  return (
    <>
      {/* {loaded && loading && (
        <div className="absolute top-0 left-0 h-full w-full z-50 bg-black bg-opacity-30 flex justify-center items-center rounded">
          <Icon.Loading size="28" />
        </div>
      )} */}
      {Boolean(unallocatedDocumentCount) && (
        <div className="border border-yellow bg-yellow-light flex py-2.5 px-4 items-center rounded mb-2">
          <Common.Checkbox
            disabled={isETAsLoading}
            checked={unallocateChecked}
            onChange={(e) => onCheckUnallocate(e.target.checked)}
            ipSize="md"
          />
          <Icon.InfoFill className="ml-4 fill-yellow" />
          <span className="ml-1.5 text-sm text-yellow font-semibold">
            {unallocatedDocumentCount} unallocated orders
          </span>
          <Common.Button
            onClick={handleOpenAllocate}
            className="ml-3"
            btSize="sm"
            outline
            disabled={isETAsLoading}
          >
            Allocate now
          </Common.Button>
        </div>
      )}
      {/* No content */}
      <div
        className={clsx(
          unallocatedDocumentCount
            ? "h-[calc(100vh-22rem)]"
            : "h-[calc(100vh-18.375rem)]",
          "flex justify-center items-center h-"
        )}
      >
        {isRoutes || isOptimized ? (
          <CustomScrollbar>
            {manifests &&
              manifests.map((item: manifest, index: number) => (
                <DeliveryItem
                  key={item.id}
                  isFirst={!index}
                  data={item}
                  canCheck={CanModifyRouteByStatus.includes(item.status)}
                  isChecked={routesChecked.includes(item.id)}
                  onCheck={(id: string) => onCheckRoute(id)}
                  isETAsLoading={isETAsLoading}
                />
              ))}
            {/* <DeliveryItem
              onViewDetail={handleOpenDetail}
              optimized={isOptimized}
            />
            <DeliveryItem onViewDetail={handleOpenDetail} /> */}
          </CustomScrollbar>
        ) : (
          <>
            {!loading ? (
              <>
                {searchKeyword && !manifests.length ? (
                  <Common.NoData
                    iconRender={Icon.Search}
                    iconClassName="stroke-neutral-30"
                    title="No result founded"
                    description="Please try another keyword"
                  />
                ) : isToday ? (
                  <Common.NoData
                    iconRender={Icon.Split}
                    title="You haven't created any route yet"
                    description="Create new route to manage your orders"
                    bottomElement={
                      <RedirectToOUI to={"/delivery/routes/create"}>
                        <Common.Button
                          className="mt-8"
                          iconLeft={Icon.PlusBorder}
                        >
                          Create new route
                        </Common.Button>
                      </RedirectToOUI>
                    }
                  />
                ) : (
                  <Common.NoData
                    iconRender={Icon.Split}
                    title={`No data on ${moment(date).format(
                      TIME_FORMAT.GENERAL_DISPLAY
                    )}`}
                  />
                )}
              </>
            ) : (
              <Common.NoData
                iconRender={Icon.Loading}
                title="Loading manifests...."
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
