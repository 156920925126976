import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import { Common, Icon } from "@/components";
import { SnackbarUtils } from "@/utils";
import {
  PATH,
  TIME_FORMAT,
  CanModifyRouteByStatus,
  BUILD_MODE,
  ENV,
} from "@/configuration/globalVariable";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { GoogleMap } from "./Map";
import { ModalOptimize } from "./ModalOptimize";
import { DeliveryList } from "./DeliveryList";
import { OptimizingList } from "./OptimizingList";
import { StorageAddress } from "./StorageAddress";

import {
  fetchAllManifestByDateAsync,
  replaceManifestsUpdatedETAs,
} from "../providers/deliveryManifest/slice";
import {
  manifestsSelector,
  unallocatedDocumentCountSelector,
  deliveryManifestLoading,
  storageAddressSelector,
  deliveryManifestLoaded,
} from "../providers/deliveryManifest/selector";
import {
  manifest,
  manifestLine,
  storageAddress,
} from "../models/DeliveryManifest";
import {
  finishPendingRequests,
  getRouteETAWithMapIds,
} from "../services/deliveryManifest";
import { mergeETAsIntoRoutes } from "../utils";

export const TabAllocation = ({ date, setDate }: any) => {
  const [startDate, setStartDate] = useState(date);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenOptimizeModal, setOpenOptimizeModal] = useState(false);
  const [isOptimizing, setOptimizing] = useState(false);
  const [isOptimized, setOptimized] = useState(false);
  const [isETALoading, setETALoading] = useState(false);

  const [routesSelected, setRoutesSelected] = useState<string[]>([]);
  const [unallocateChecked, setUnallocateChecked] = useState(false);
  const manifests = useAppSelector(manifestsSelector);
  const isDeliveryManifestLoaded = useAppSelector(deliveryManifestLoaded);
  const loading = useAppSelector(deliveryManifestLoading);
  const unallocatedDocumentCount = useAppSelector(
    unallocatedDocumentCountSelector
  );

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  // (date) => useCallback(() => setStartDate(date), [])
  const handleChangeDateToFetch = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  const results = useMemo(
    () =>
      manifests
        ? manifests.filter((item: manifest) => {
            if (
              item.route.name &&
              item.route.name
                .toUpperCase()
                .indexOf(searchKeyword.toUpperCase()) > -1
            )
              return true;
            if (
              item.driver.givenName &&
              item.driver.givenName
                .toUpperCase()
                .indexOf(searchKeyword.toUpperCase()) > -1
            )
              return true;
            if (
              item.driver.lastName &&
              item.driver.lastName
                .toUpperCase()
                .indexOf(searchKeyword.toUpperCase()) > -1
            )
              return true;
            if (item.manifestLines.length) {
              return item.manifestLines.filter(
                (manifestLine: manifestLine) =>
                  manifestLine.document.customerTradingName &&
                  manifestLine.document.customerTradingName
                    .toUpperCase()
                    .indexOf(searchKeyword.toUpperCase()) > -1
              ).length;
            }
            return false;
          })
        : [],
    [searchKeyword, manifests]
  );

  const handleEditRoutes = () => {
    // will todo
    const routesActive = [] as manifest[];
    const routesUnActive = [] as manifest[];
    manifests.map((manifest: manifest) => {
      if (routesSelected.includes(manifest.id)) {
        routesActive.push(manifest);
      }
      if (
        !(
          !routesSelected.includes(manifest.id) &&
          CanModifyRouteByStatus.includes(manifest.status)
        )
      ) {
        routesUnActive.push(manifest);
      }
      return manifest;
    });

    navigate(`/${PATH.DELIVERY}/${PATH.EDIT_ROUTES}`, {
      state: {
        unallocate: unallocateChecked,
        deliveryDate: date,
        routesActive: unallocateChecked
          ? [{}].concat(routesActive)
          : routesActive,

        routesUnActive,
      },
    });
  };

  const handleSubmitOptimize = () => {
    setOpenOptimizeModal(false);
    setOptimizing(true);
  };

  const handleCompleteOptimization = () => {
    setOptimizing(false);
    setOptimized(true);
    SnackbarUtils.success(
      "Applied 3 routes successfully. The delivery process will start based on the time you have set."
    );
  };

  const fetchManifestList = (date: any) => {
    finishPendingRequests("RefreshList");
    dispatch(
      fetchAllManifestByDateAsync({
        date: moment(date).format(TIME_FORMAT.REQUEST),
        isFetchETA: true,
      })
    ).then((mnf) => {
      const manifestsPayload = mnf.payload.manifests;
      if (manifestsPayload && manifestsPayload.length) {
        handleFetchandMergeETAIntoRoutes(manifestsPayload);
      }
    });
  };

  const handleChangeDate = (date: any) => {
    finishPendingRequests("changeDate");
    setDate(date);
    setRoutesSelected([]);
    fetchManifestList(date);
  };

  useEffect(() => {
    if (!isDeliveryManifestLoaded) {
      fetchManifestList(location.state?.deliveryDate || date);
      if (location.state?.deliveryDate) {
        setDate(new Date(location.state?.deliveryDate));
        window.history.replaceState({}, document.title);
      }
    }
    return () => {
      // clear checkbox component didmount
      finishPendingRequests("cancelFetchETAs");
      setRoutesSelected([]);
    };
    // eslint-disable-next-line
  }, []);

  const handleFetchandMergeETAIntoRoutes = (manifestsPayload: manifest[]) => {
    setETALoading(true);
    const idsToFetchETA = manifestsPayload
      .map((manifestItem: manifest) => {
        return manifestItem.manifestLines.length ? manifestItem.id : null;
      })
      .filter((id: string | null) => id);

    const idsToFetch = [];
    const totalRoutes = manifestsPayload.length;
    const routePerGroup = Math.ceil(
      totalRoutes > 30
        ? totalRoutes / Math.ceil(totalRoutes / 10)
        : totalRoutes / 3
    );

    while (idsToFetchETA.length > 0) {
      idsToFetch.push(idsToFetchETA.splice(0, routePerGroup));
    }
    if (idsToFetch.length) {
      // Fetch etas by ids and merge into routes
      getETAsGroup(idsToFetch, manifestsPayload);
    }
  };

  const getETAsGroup = async (idGroup: any[], manifestsPayload: manifest[]) => {
    let newManifests = [...manifestsPayload] as manifest[];
    await Promise.all(
      idGroup.map((ids) =>
        getRouteETAWithMapIds(ids).then((ETAs) => {
          newManifests = mergeETAsIntoRoutes(newManifests, ETAs) as manifest[];
          dispatch(replaceManifestsUpdatedETAs(newManifests));
        })
      )
    );
    setETALoading(false);
  };

  const handleSelectRouteList = (routeId: string) => {
    setRoutesSelected(
      routesSelected.includes(routeId)
        ? routesSelected.filter((rId) => rId !== routeId)
        : routesSelected.concat(routeId)
    );
  };

  const handleCheckAllRoutes = () => {
    if (
      routesSelected.length ===
      manifests.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status)
      ).length
    ) {
      setRoutesSelected(
        searchKeyword
          ? routesSelected.filter(
              (id: string) => !results.find((i: manifest) => i.id === id)
            )
          : []
      );
      unallocatedDocumentCount && setUnallocateChecked(false);
    } else {
      unallocatedDocumentCount && setUnallocateChecked(true);
      setRoutesSelected(
        results
          .filter((mnf: manifest) =>
            CanModifyRouteByStatus.includes(mnf.status)
          )
          .map((item: manifest) => item.id)
      );
    }
  };

  // filter manifest line can check;
  const manifestCanCheck =
    (results &&
      results.length &&
      results.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status)
      )) ||
    [];
  // check all condition
  const isCheckedAll = manifestCanCheck.length
    ? routesSelected.length === manifestCanCheck.length &&
      manifestCanCheck.length !== 0
    : false;

  return (
    <>
      <div className="pb-5 flex items-center">
        <Common.Input
          wrapClassName="2xl:lg:w-[26.375rem] mr-3"
          iconRight={Icon.Search}
          iconType="stroke"
          disabled={loading}
          placeholder="Search customer, route, driver name..."
          value={searchKeyword}
          onChange={(e: any) => setSearchKeyword(e.target.value)}
          onClear={() => setSearchKeyword("")}
        />
        <div className="max-w-[12.5rem]">
          <Common.DatePicker
            disabled={loading}
            selected={startDate}
            onChange={handleChangeDateToFetch}
            onSelect={handleChangeDate}
          />
        </div>
        <span data-tip="Refresh manifest list" className="ml-2">
          <Common.Button
            onClick={() => fetchManifestList(date)}
            disabled={loading}
            iconLeft={Icon.Reload}
            iconType="stroke"
          />
        </span>
        <StorageAddress />
        {BUILD_MODE === ENV.DEV && (
          <Common.Button
            onClick={() => setOpenOptimizeModal(true)}
            iconLeft={Icon.Split}
            outline
            data-tip="Select the route and click this button to optimize the way for your route"
            disabled={!routesSelected.length}
            className="whitespace-nowrap"
          >
            Optimize routes
          </Common.Button>
        )}
        <Common.Button
          className="ml-3 whitespace-nowrap"
          iconLeft={Icon.Edit}
          outline
          onClick={handleEditRoutes}
          disabled={!routesSelected.length || isETALoading}
        >
          Edit routes
        </Common.Button>
      </div>
      <div className="flex">
        <div className="w-[26.375rem] mr-4 relative">
          <div className="flex items-center mb-1 h-[1.5rem]">
            <Common.Checkbox
              label="ROUTES"
              id="ROUTES"
              ipSize="md"
              disabled={isETALoading}
              wrapClassName={clsx("ml-4", !manifests?.length && "hidden")}
              checked={
                unallocatedDocumentCount
                  ? isCheckedAll && unallocateChecked
                  : isCheckedAll
              }
              onChange={handleCheckAllRoutes}
            />
          </div>
          {isOptimizing ? (
            <OptimizingList onCompleted={handleCompleteOptimization} />
          ) : (
            <DeliveryList
              date={date}
              manifests={results}
              isOptimized={isOptimized}
              isETAsLoading={isETALoading}
              routesChecked={routesSelected}
              onCheckRoute={handleSelectRouteList}
              searchKeyword={searchKeyword}
              unallocatedDocumentCount={unallocatedDocumentCount}
              unallocateChecked={unallocateChecked}
              onCheckUnallocate={setUnallocateChecked}
            />
          )}
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          <div className="z-0 w-full h-full">
            <GoogleMap storageAddress={storageAddress} manifests={manifests} />
          </div>
        </div>
      </div>

      <ModalOptimize
        open={isOpenOptimizeModal}
        onClose={() => setOpenOptimizeModal(false)}
        onSubmit={handleSubmitOptimize}
      />
    </>
  );
};
