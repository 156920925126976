import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { ModalContent, Card, GoogleMap, Common, Icon } from "@/components";
import { EyeMapDropdown } from "./EyeMapDropdown";
import { OptimizedReviewItem } from "./OptimizedReviewItem";
import { OptimizedReviewDetail } from "./OptimizedReviewDetail";
import clsx from "clsx";

interface Props {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSubmit: () => void;
}

export const ModalOptimized = ({
  open,
  onClose,
  onDiscard,
  onSubmit,
}: Props) => {
  const [isOpenDetail, setOpenDetail] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <ModalContent open={open} panelClassWidth="max-w-[71.625rem] w-full">
      <Card
        title="Optimized 3 Routes"
        className={clsx(isOpenDetail && "hidden")}
      >
        <div className="flex space-x-4 p-4 min-h-[50vh]">
          <div className="w-[26.5rem]">
            <div className="flex border border-yellow bg-yellow-light py-2.5 px-4 rounded mb-4">
              <div className="mt-1">
                <Icon.InfoFill size="16" className="fill-yellow" />
              </div>
              <div className="flex-1 flex items-center">
                <div className="flex-1 mx-2">
                  Don&apos;t have enough route to deliver all orders.{" "}
                  <span className="text-yellow">10 unallocated orders</span> is
                  waiting for allocating.
                </div>
                <div>
                  <Common.ViewDetailBtn onClick={() => setOpenDetail(true)} />
                </div>
              </div>
            </div>

            <div className="max-h-[calc(100vh-25rem)] overflow-y-auto">
              <OptimizedReviewItem
                onOpenDetail={() => setOpenDetail(true)}
                isFirst
              />
              <OptimizedReviewItem onOpenDetail={() => setOpenDetail(true)} />
              <OptimizedReviewItem onOpenDetail={() => setOpenDetail(true)} />
              <OptimizedReviewItem onOpenDetail={() => setOpenDetail(true)} />
              <OptimizedReviewItem onOpenDetail={() => setOpenDetail(true)} />
            </div>
          </div>
          <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
            <div className="ralative z-0 w-full h-full">
              <GoogleMap />
            </div>
            <div className="absolute bottom-3 right-3 z-10">
              <EyeMapDropdown />
            </div>
          </div>
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 items-center">
          <div className="flex-1 flex mr-4">
            <Common.Button onClick={onClose} color="transparent">
              Cancel
            </Common.Button>
          </div>
          <Common.Button onClick={onDiscard} outline color="red">
            Discard
          </Common.Button>
          <Common.Button onClick={onSubmit}>Optimize</Common.Button>
        </div>
      </Card>

      {isOpenDetail && (
        <OptimizedReviewDetail onCloseDetail={() => setOpenDetail(false)} />
      )}
    </ModalContent>
  );
};
