import clsx from "clsx";
import { Common, Icon } from "@/components";

interface Props {
  isFirst?: boolean;
  onOpenDetail: () => void;
}

export const OptimizedReviewItem = ({ isFirst, onOpenDetail }: Props) => {
  return (
    <div
      className={clsx(
        "border border-neutral-20 bg-white px-4 pb-4 pt-3 rounded",
        !isFirst && "mt-3"
      )}
    >
      <div className="border-b border-neutral-20 flex items-center pb-3">
        <div className="flex-1 mr-4">
          <div className="flex items-center mb-2">
            <Common.DotColor />
            <div className="flex-1 flex items-center">
              <div
                className="font-semibold text-neutral-80 mx-2 line-clamp-1"
                data-tip="South to West - Route AB"
              >
                South to West - Route AB South to West - Route AB South to West
                - Route AB South to West - Route AB South to West - Route AB
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Icon.Driver size="16" className="fill-neutral-40" />
            <div className=" ml-1.5">Arlene McCoy </div>
            <Icon.Order size="12" className="fill-neutral-40 ml-4 mr-1.5" />
            <div>12 orders</div>
          </div>
        </div>
        <Common.ViewDetailBtn onClick={onOpenDetail} />
      </div>
      <div className="mt-3">
        <div className="flex items-center">
          <div className="text-neutral-70">
            <div className="text-xs text-neutral-50">Start time</div>
            06:40AM
          </div>
          <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
          <div className="text-neutral-70">
            <div className="text-xs text-neutral-50">End time</div>
            04:30PM
          </div>

          <div className="text-neutral-70 ml-10">
            <div className="text-xs text-neutral-50">Driver break period</div>
            12:00AM - 1:30PM
          </div>
        </div>
      </div>
    </div>
  );
};
