import { useEffect, useState } from "react";
import { OptimizingGroup } from "./OptimizingGroup";

interface Props {
  onCompleted: () => void;
}

export const OptimizingList = ({ onCompleted }: Props) => {
  const [isOptimizeCompleted, setOptimizeCompleted] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOptimizeCompleted(true);
    }, 2000);
  }, []);

  return (
    <div className="h-[calc(100vh-20rem)] overflow-y-auto">
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isFirst
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isWarning={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
      <OptimizingGroup
        onOptimizedCompleted={onCompleted}
        isSuccess={isOptimizeCompleted}
      />
    </div>
  );
};
