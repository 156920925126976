import { useState } from "react";
import clsx from "clsx";
import { Icon } from "@/components";
import { DeliveryLineStatusValue } from "@/configuration/globalVariable";
import { DestinationDetailItem } from "./DestinationDetailItem";
import { manifest, manifestLine } from "../models/DeliveryManifest";
import { DepotItem } from "./DepotItem";

interface Props {
  data: manifestLine[];
  routeInfo: manifest;
  manifestETALoading?: boolean;
}

export const DeliveryRouteDetailList = ({
  data,
  routeInfo,
  manifestETALoading,
}: Props) => {
  const [isDisplayCompletedItem, setDisplayCompletedItem] = useState(true);
  const deliveredCount = data.filter(
    (mfi) => mfi.deliveryStatus === DeliveryLineStatusValue.COMPLETED
  ).length;

  return (
    <>
      {Boolean(deliveredCount) && (
        <div
          className="flex items-center px-3 py-2 rounded mx-4 font-medium border border-neutral-10 bg-white mb-2 cursor-pointer select-none"
          onClick={() => setDisplayCompletedItem(!isDisplayCompletedItem)}
        >
          <div className="w-5 h-5 rounded-full flex items-center justify-center bg-green mr-2">
            <Icon.Checked size="12" className="fill-white" />
          </div>
          {deliveredCount} Orders completed
          <button
            data-tip="Collapse/Expand order completed items"
            className="ml-auto"
          >
            {isDisplayCompletedItem ? (
              <Icon.AngleDown size="12" className="stroke-neutral-40" />
            ) : (
              <Icon.AngleUp size="12" className="stroke-neutral-40" />
            )}
          </button>
        </div>
      )}
      <DepotItem />
      {data.map((item, index) => (
        <DestinationDetailItem
          key={item.id}
          orderLineNumber={index + 1}
          data={item}
          routeInfo={routeInfo}
          manifestETALoading={manifestETALoading}
          className={clsx(
            !isDisplayCompletedItem &&
              item.deliveryStatus === DeliveryLineStatusValue.COMPLETED &&
              "!hidden"
          )}
        />
      ))}
      {routeInfo?.driver?.returnToDepot && <DepotItem className="mt-2" />}
    </>
  );
};
