import { useEffect, useState } from "react";
import { Images } from "@/assets/images";
import {
  DeliveryLineStatusValue,
  DeliveryStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import {
  manifest,
  manifestDetailETA,
  manifestLine,
  manifestLineETA,
  storageAddress,
} from "@/pages/Delivery/models";
import { ETATimeFormat } from "@/pages/Delivery/utils";
import {
  addressGenerate,
  checkTimeOfWindow,
  distanceFormat,
  timeFormat,
  timeStopFormat,
  timeWorkingFormat,
} from "@/utils";
import "./marker.css";

interface Props {
  data: manifestLine | storageAddress;
  position: {
    lat: number;
    lng: number;
  };
  isStore?: boolean;
  isLast?: boolean;
  manifest?: manifest;
  manifestETAData?: manifestDetailETA;
  manifestLineETAData?: manifestLineETA;
}

const Marker = ({
  data,
  manifest,
  position,
  isStore,
  isLast,
  // manifestETAData,
  // manifestLineETAData,
  ...options
}: Props) => {
  const [marker, setMarker] = useState<any>();
  const route = manifest?.route;
  const workingTime = timeWorkingFormat(manifest?.workingTime || 0);
  const distance = distanceFormat(manifest?.distance || 0);
  const driverName = `${(manifest?.driver as any)?.givenName}${
    (manifest?.driver as any)?.lastName
      ? `" "${(manifest?.driver as any)?.lastName}`
      : ""
  }`;

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  });

  const infoWindow = new google.maps.InfoWindow();

  const markerIcon = (number: string) =>
    isStore
      ? Images.markerStore
      : !isLast
      ? "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 28 36" style="enable-background:new 0 0 28 36;" xml:space="preserve"  width="28" height="36">
 <path fill="${route?.colour}" d="M14,1C7.6,1,1,6.1,1,14.5c0,2.1,0.7,4.3,1.8,6.5c1,2.2,2.4,4.3,3.8,6.3c2.8,3.8,5.6,6.8,5.8,7
   c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,3-3.2,5.8-7
   c1.4-1.9,2.8-4.1,3.8-6.3c1-2.2,1.8-4.4,1.8-6.5C27,6.1,20.4,1,14,1z"/>
 <circle fill="#FFFFFF" cx="14" cy="14.1" r="10.1" />
 <text x="13" y="15" dominant-baseline="middle" text-anchor="middle" fill="#0f172a" font-family="Inter, sans-serif" font-size="14" font-weight="600" width="30" text-align="center">${number}</text>
 </svg>`)
      : "data:image/svg+xml;charset=UTF-8," +
        encodeURIComponent(`<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 28 36" style="enable-background:new 0 0 28 36;" xml:space="preserve"  width="28" height="36">
 <path d="M14,1C7.6,1,1,6.1,1,14.5c0,2.1,0.7,4.3,1.8,6.5c1,2.2,2.4,4.3,3.8,6.3c2.8,3.8,5.6,6.8,5.8,7
   c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,3-3.2,5.8-7
   c1.4-1.9,2.8-4.1,3.8-6.3c1-2.2,1.8-4.4,1.8-6.5C27,6.1,20.4,1,14,1z" fill="${route?.colour}" />
   <circle fill="#FFFFFF" cx="14" cy="14.1" r="10.1" />
 <path d="M19,12.8l1.7-4.4H10.3c0-0.5-0.3-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9v12.2c0,0.5,0.3,0.9,0.9,0.9
   c0.5,0,0.9-0.3,0.9-0.9v-3.5h10.4L19,12.8z" fill="${route?.colour}" />
 </svg>`);

  useEffect(() => {
    if (marker && data) {
      const icon = {
        url: markerIcon(((data as manifestLine)?.position + 1).toString()),
        size: new google.maps.Size(28, 36),
        StyleSheet,
      };

      marker.setOptions({
        ...options,
        position,
        icon,
        // label:
        //   !isStore && !isLast
        //     ? {
        //         text: ((data as manifestLine)?.position + 1).toString(),
        //         className: "point-number",
        //       }
        //     : "",
      });

      google.maps.event.clearInstanceListeners(marker);

      marker.addListener("mouseover", () => {
        marker.setOptions({ zIndex: 1 });
      });
      marker.addListener("mouseout", () => {
        marker.setOptions({ zIndex: 0 });
      });

      marker.addListener("click", () => {
        if (infoWindow.getContent()) {
          infoWindow.setContent();
          infoWindow.close();
        } else {
          infoWindow.setContent(
            isStore
              ? buildStoreContent(data)
              : buildContent(data as manifestLine)
          );
          infoWindow.open(marker.getMap(), marker);
        }
      });

      infoWindow.addListener("closeclick", () => {
        infoWindow.setContent();
        infoWindow.close();
      });
    }
    // eslint-disable-next-line
  }, [marker, position, options, data]);

  const buildContent = (property: manifestLine) => {
    const ETATime = ETATimeFormat(
      property,
      // manifest?.manifestLines,
      manifest?.status
    );

    const earliest = timeFormat(property?.earliest, true);
    const latest = timeFormat(
      property?.latest === "00:00:00" ? "23:59:00" : property?.latest,
      true
    );

    const isOutOfTimeWindow = checkTimeOfWindow(earliest, latest, ETATime);

    return `<div class="manifest-marker">
      <div class="details">
        <div class="route-info">
          <div class="box-name">
            <div class="dot-color" style="background-color: ${
              route?.colour
            }"></div>
            ${route?.name}
            <div class="status-label" style="background-color: ${
              DeliveryStatus[manifest?.status || 0].colour
            }; display: ${
      manifest?.status === DeliveryStatusValue.PENDING ? "none" : "block"
    };">
              ${DeliveryStatus[manifest?.status || 0].label}
            </div>
          </div>
          <div class="lineWrap">
            <div class="iconLine">
              <img src=".${Images.distant}" alt="" />
            </div>
            <div class="textLine"><span>Total distance: </span>${distance}</div>
            <div class="iconLine" style="margin-left: 12px">
              <img src=".${Images.clock}" alt="" />
            </div>
            <div class="textLine"><span>Total time: </span>${workingTime}</div>
          </div>
          <div class="lineWrap">
            <div class="iconLine">
              <img src=".${Images.driver}" alt="" />
            </div>
            <div class="textLine"><span>Driver's name: </span>${driverName}</div>
          </div>
          <div class="lineWrap" style="margin-bottom: 0">
            <div class="iconLine">
              <img src=".${Images.order}" alt="" />
            </div>
            <div class="textLine"><span>Completed order: </span>
            ${
              manifest?.manifestLines.filter(
                (mnf: manifestLine) =>
                  mnf.deliveryStatus === DeliveryLineStatusValue.COMPLETED
              ).length
            } / ${manifest?.manifestLines.length} order${
      manifest?.manifestLines && manifest?.manifestLines.length > 1 ? "s" : ""
    } 
            </div>
          </div>
        </div>
        <div>
          <div class="box-name">
            <div>
              <div class="box-position" style=" 
                ${
                  property.deliveryStatus === DeliveryLineStatusValue.PENDING
                    ? "background-color: #E2E8F0; color: var(--color-neutral-80);"
                    : ""
                }
                ${
                  property.deliveryStatus === DeliveryLineStatusValue.DELIVERING
                    ? "background-color: #136BA6;"
                    : ""
                }
                ${
                  property.deliveryStatus === DeliveryLineStatusValue.COMPLETED
                    ? "background-color: #29BA74;"
                    : ""
                }
              ">
                ${
                  property.deliveryStatus === DeliveryLineStatusValue.COMPLETED
                    ? `<img src=".${Images.checked}" alt="" />`
                    : property.position + 1
                }
              </div>
            </div>
            <span style="color: #136BA6; white-space: nowrap; margin-right: 4px;">${
              property.document.saleDocumentNumber
            } - </span>
            <span>${property.document.customerTradingName}</span>
          </div>

          <div class="lineWrap" style="display: flex; align-items: center; ${
            property.deliveryStatus !== DeliveryLineStatusValue.COMPLETED &&
            "display: none;"
          }">
            <div class="iconLine">
              <img src=".${Images.clock}" alt="" />
            </div>
            <div class="textLine"><span>Arrived time: </span> <span style="${
              isOutOfTimeWindow ? "color: var(--color-yellow-dark)" : ""
            }">${ETATime}</span></div>
            <div class="outTimeLabel" style="${
              !isOutOfTimeWindow && "display: none;"
            }"><img src=".${
      Images.warningCircle
    }" alt="" />Out of time window</div>
          </div>

          <div class="lineWrap">
            <div class="iconLine">
              <img src=".${Images.deliveryTime}" alt="" />
            </div>
            <div class="textLine"><span>Time window: </span> ${earliest} - ${latest}</div>
          </div>

          <div class="lineWrap" style="${
            property.deliveryStatus === DeliveryLineStatusValue.COMPLETED &&
            "display: none;"
          }">
            <div class="iconLine">
              <img src=".${Images.clock}" alt="" />
            </div>
            <div class="textLine"><span>ETA: </span> ${ETATime}</div>
          </div>
          <div class="lineWrap" style="${
            property.deliveryStatus === DeliveryLineStatusValue.COMPLETED &&
            "display: none;"
          }">
            <div class="iconLine">
              <img src=".${Images.stock}" alt="" />
            </div>
            <div class="textLine"><span>Stop time: </span> ${
              property.stopTime && timeStopFormat(property.stopTime)
            }</div>
          </div>



          <div class="lineWrap">
            <div class="iconLine">
              <img src=".${Images.location}" alt="" />
            </div>
            <div class="textLine"><span>Address: </span> ${addressGenerate(
              property?.document.deliveryAddressLine1,
              property?.document.deliveryAddressLine2,
              property?.document.deliverySuburb,
              property?.document.deliveryState,
              property?.document.deliveryPostCode,
              property?.document.deliveryCountry
            )}</div>
          </div>
        </div>
      </div>
      </div>
    `;
  };

  const buildStoreContent = (property: any) => {
    return `<div class="manifest-marker">
    <div class="details">
      <div class="box-name">${property.name}</div>
      <div class="lineWrap">
        <div class="iconLine">
          <img src=".${Images.location}" alt="" />
        </div>
        <div class="textLine"><span>Address:</span> ${addressGenerate(
          property?.addressLine1,
          property?.addressLine2,
          property?.suburb,
          property?.state,
          property?.postcode,
          property?.country
        )}</div>
      </div>
    </div>
    </div>
  `;
  };

  return null;
};

export default Marker;
