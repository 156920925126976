import { Common, Icon, LayoutPartials, Table, Td, Th } from "@/components";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { RouteAllocateSelectbox } from "../components";

const data = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const Allocate = () => {
  const navigate = useNavigate();

  return (
    <LayoutPartials.BodyContent
      pageTitle="Allocate 24 orders"
      onTitleBack={() => navigate(-1)}
    >
      <div className="h-[calc(100vh-15.65rem)] overflow-y-auto border border-neutral-20">
        <Table>
          <thead>
            <tr className="divide-x divide-neutral-20">
              <Th className="text-center w-11">#</Th>
              <Th className="">Order #</Th>
              <Th className="w-96">Route</Th>
              <Th className="w-48">Arrange</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index + item + index}
                className="divide-x divide-neutral-20"
              >
                <Td
                  className={clsx("text-center", "border-b border-neutral-20")}
                >
                  {index + 1}
                </Td>
                <Td className={"border-b border-neutral-20"}>
                  <div className="font-semibold">
                    <span className="text-blue">#1251</span> - That Armore
                    Cheese
                  </div>
                  <div className="flex items-center w-full max-w-[calc(100vw-60rem)]">
                    <Icon.Location size="12" className="fill-neutral-40 mr-2" />
                    <div className="flex-1 min-w-0">
                      <div className="w-full truncate">
                        166 Balnarring Road Merricks north, VIC, 3199, Australia
                        166 Balnarring Road Merricks north, VIC, 3199, Australia
                        166 Balnarring Road Merricks north, VIC, 3199, Australia
                        166 Balnarring Road Merricks north, VIC, 3199, Australia
                      </div>
                    </div>
                  </div>
                </Td>
                <Td className={"border-b border-neutral-20"}>
                  <RouteAllocateSelectbox />
                </Td>
                <Td className={"border-b border-neutral-20"}>
                  <Common.Selectbox
                    options={[
                      {
                        id: 1,
                        text: "Last",
                        value: "last",
                      },
                      {
                        id: 2,
                        text: "First",
                        value: "first",
                      },
                    ]}
                  />
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="flex items-center pt-3">
        <Common.Button color="transparent">Close</Common.Button>
        <Common.Checkbox
          label="Apply for the future"
          wrapClassName="ml-auto mr-4"
        />
        <Common.Button outline>Save</Common.Button>
      </div>
    </LayoutPartials.BodyContent>
  );
};

export default Allocate;
