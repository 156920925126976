import { forwardRef } from "react";
import clsx from "clsx";
import { Icon } from "@/components";
import { addressGenerate } from "@/utils";
import { useAppSelector } from "@/sharedStore/hooks";
import { storageAddressSelector } from "../providers/deliveryManifest/selector";
import { storageAddress } from "../models/DeliveryManifest";

interface Props {
  className?: string;
}

export const DepotItem = forwardRef(({ className }: Props, ref) => {
  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  return (
    <div
      ref={ref as any}
      className={clsx(
        "px-3 py-2 flex border rounded mx-4 cursor-pointer",
        "border-neutral-10 bg-white",
        className
      )}
    >
      <div>
        <div
          className={clsx(
            "w-5 h-5 rounded-full font-semibold flex items-center justify-center",
            "bg-neutral-10 text-neutral-50"
          )}
        >
          <Icon.Shop size="12" className="fill-neutral-40" />
        </div>
      </div>
      <div className="flex-1 ml-4">
        <div className="font-semibold">Depot</div>
        <div className="flex mt-2">
          <div className="mt-1" data-tip="Depot address">
            <Icon.Location size="12" className="fill-neutral-40" />
          </div>
          <div className="ml-1.5 text-neutral-70">
            {addressGenerate(
              storageAddress?.addressLine1,
              storageAddress?.addressLine2,
              storageAddress?.suburb,
              storageAddress?.state,
              storageAddress?.postcode,
              storageAddress?.country
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
