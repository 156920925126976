import { Common, Icon } from "@/components";
import clsx from "clsx";
import { useState } from "react";

const data = [
  {
    id: 1,
    route: "South to West - Route AB West - Route AB West - Route AB",
  },
  {
    id: 2,
    route: "Route West to South",
  },
  {
    id: 3,
    route: "South to West",
  },
  {
    id: 4,
    route: "West to South",
  },
  {
    id: 5,
    route: "South to West - Route",
  },
  {
    id: 6,
    route: "Route West to South Africa",
  },
  {
    id: 7,
    route: "South to West VietNam",
  },
  {
    id: 8,
    route: "West to South Autralia",
  },
];

const Item = ({ item, isFirst }: any) => {
  return (
    <>
      <div
        className={clsx(
          "cursor-pointer px-3 py-4 select-none hover:bg-neutral-10 w-full",
          !isFirst && "shadow-overline"
        )}
      >
        <div className="flex items-center pl-0.5 w-full">
          <div>
            <Common.DotColor />
          </div>
          <div className="flex-1 min-w-0 font-semibold ml-2">
            <div className="w-full truncate">{item.route}</div>
          </div>
        </div>
        <div className="flex items-center mt-2">
          <Icon.Driver size="16" className="fill-neutral-40" />
          <div className=" ml-1.5">Arlene McCoy </div>
          <Icon.Order size="12" className="fill-neutral-40 ml-4 mr-1.5" />
          <div>12 orders</div>
        </div>
      </div>
    </>
  );
};

export const RouteAllocateSelectbox = () => {
  const [selected, setSelected] = useState<any>(null);
  const handleDisplayValue = () => {
    return selected ? (
      <div className="flex items-center pl-0.5">
        <div>
          <Common.DotColor />
        </div>
        <div className="flex-1 min-w-0 font-semibold ml-2">
          <div className="w-full truncate">{selected?.route}</div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  return (
    <Common.InputSearchContent
      data={data}
      renderItem={Item}
      placeholder="Select or search for route"
      keySearch="route"
      displayValue={handleDisplayValue}
      onSelect={setSelected}
    />
  );
};
