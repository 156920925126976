import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import {
  Common,
  Icon,
  LayoutPartials,
  ModalInform,
  DeliveryStatusLabel,
  CustomScrollbar,
} from "@/components";
import {
  CanModifyRouteByStatus,
  DeliveryStatusValue,
  PATH,
  TIME_FORMAT,
} from "@/configuration/globalVariable";
import { timeFormat, SnackbarUtils } from "@/utils";

import { GoogleMap } from "../components/Map";
import {
  DeliveryRouteDetailList,
  DeliveryRouteDetailEditingList,
} from "../components";

import {
  manifest,
  manifestDetailETATime,
  manifestLine,
  storageAddress,
} from "../models/DeliveryManifest";
import {
  useSaveEditingRoutes,
  useForceStopDelivering,
  getManifestDetailTimeETA,
  getRouteById,
  getRouteETAWithMapIds,
  finishPendingRequests,
} from "../services/deliveryManifest";
import { useAppSelector, useAppDispatch } from "@/sharedStore/hooks";
import {
  manifestsSelector,
  storageAddressSelector,
} from "../providers/deliveryManifest/selector";
import {
  fetchAllManifestByDateAsync,
  updateManifests,
} from "../providers/deliveryManifest/slice";
import { sortManifestLine } from "../utils";
import { useGetRouteDetailById } from "../providers/deliveryManifest/hooks";

const DeliveryRouteDetail = () => {
  const params = useParams();
  const { routeDetailId } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const passedData = sortManifestLine((location.state as any)?.data);
  const getRouteDetailById = useGetRouteDetailById();
  const [isOpenSaveEditingModal, setOpenSaveEditingModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [routeDetail, setRouteDetail] = useState<manifest | any>(
    routeDetailId && getRouteDetailById(routeDetailId)
  );
  const [routeEditing, setRouteEditing] = useState<manifest | any>();
  const [routesMoving, setRoutesMoving] = useState<manifest[]>([]);
  const [isDirty, setDirty] = useState(false);
  const [unallocateList, setUnallocate] = useState<manifestLine[]>([]);
  const [isOpenBackInformModal, setIsOpenBackInformModal] = useState(false);
  const [isManifestETALoading, setIsManifestETALoading] =
    useState<boolean>(false);

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;
  const manifests = useAppSelector(manifestsSelector) as manifest[];
  const isEditing = Boolean(routeEditing);
  const destinationNumber = !isEditing
    ? routeDetail?.manifestLines?.length
    : routeEditing?.manifestLines?.length;

  const handleBackNoneData = () => {
    navigate(`/${PATH.DELIVERY}/${PATH.DELIVERY_MANIFESTS}`, {
      replace: true,
      state: { deliveryDate: routeDetail.deliveryDate },
    });
  };

  const handleBack = () => {
    setRouteEditing(undefined);
    passedData ? navigate(-1) : handleBackNoneData();
  };

  const handleEnableEdit = () => {
    setRouteEditing(routeDetail);
  };

  useEffect(() => {
    if (routeDetailId && !routeDetail) {
      handleGetRoute(routeDetailId);
      window.history.replaceState({}, document.title);
    }
  }, []);

  const handleCancel = () => {
    isDirty ? setOpenSaveEditingModal(true) : setRouteEditing(undefined);
  };

  const refetchManifests = () =>
    manifests &&
    dispatch(
      fetchAllManifestByDateAsync({
        date: moment(routeDetail.deliveryDate).format(TIME_FORMAT.REQUEST),
        isFetchETA: true,
      })
    );

  // Handle eddit future
  const saveEditingRoutes = useSaveEditingRoutes();
  const { isSuccess: saveEditSuccess, isLoading: editIsSaving } =
    saveEditingRoutes;

  const handleCancelSave = () => {
    try {
      finishPendingRequests("cancelFetchETAInEditDetailMode");
      isOpenSaveEditingModal && setOpenSaveEditingModal(false);
      setRouteEditing(undefined);
    } catch (e) {
      console.log("error: ", e);
    }
  };

  const handleSaveEditing = () => {
    if (isEmpty(routesMoving)) {
      saveEditingRoutes.mutate([routeEditing]);
    } else {
      saveEditingRoutes.mutate([routeEditing].concat(routesMoving));
    }

    isOpenSaveEditingModal && setOpenSaveEditingModal(false);
  };

  useEffect(() => {
    if (saveEditSuccess) {
      const unallocatedDocumentCount = unallocateList.length;
      const unllocated = unallocatedDocumentCount;
      SnackbarUtils.success(
        unllocated
          ? `Route <b>${
              routeDetail.route.name
            }</b> have been saved and ${unllocated} order${
              unllocated ? "s" : ""
            } unallocated.`
          : `Route <b>${routeDetail.route.name}</b> have been saved.`
      );
      setUnallocate([]);
      if (manifests.length) {
        dispatch(
          updateManifests({
            manifests: [routeEditing],
            unallocatedDocumentCount,
          })
        );
      }
      // refetchManifests();
      setRouteDetail(routeEditing);
      setRouteEditing(undefined);
      setRoutesMoving([]);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [saveEditSuccess]);

  useEffect(() => {
    if (isEditing) {
      const isDiff =
        JSON.stringify(routeDetail.manifestLines) !==
        JSON.stringify((routeEditing as manifest)?.manifestLines);

      isDiff !== isDirty && setDirty(isDiff);
    }
    // eslint-disable-next-line
  }, [routeDetail, routeEditing]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditing]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "There are some changes have been made. Are you sure to cancel all changes?";

    if (isEditing) {
      e.returnValue = message;
    } else {
      delete e.returnValue;
    }
    return message;
  };

  // End Handle eddit future

  // Handle force stop
  const forceStopDelivering = useForceStopDelivering();
  const { isSuccess: forceStopSuccess, isLoading: stopIsForcing } =
    forceStopDelivering;

  useEffect(() => {
    if (forceStopSuccess) {
      SnackbarUtils.success(
        `Route <b>${routeDetail.route.name}</b> force stop successfully.`
      );
      routeDetailId && handleGetRoute(routeDetailId);
      refetchManifests();
    }
    // eslint-disable-next-line
  }, [forceStopSuccess]);
  // End Handle force stop

  const handleGetRoute = (routeDetailId: string) => {
    setLoading(true);
    getRouteById(routeDetailId)
      .then((res) => {
        setLoading(false);
        setIsManifestETALoading(true);
        setRouteDetail(res);
        getRouteETAWithMapIds([routeDetailId])
          .then((ETARes) => {
            setIsManifestETALoading(false);
            setRouteDetail(mergeETAWithRoute(res, ETARes[0]));
          })
          .catch(() => setIsManifestETALoading(false));
      })
      .catch(() => setLoading(false));
  };

  const handleUnallocate = (manifestLine?: manifestLine) => {
    if (manifestLine) {
      const list = [...unallocateList, manifestLine];
      setUnallocate(list);
      const route = {
        ...routeEditing,
        manifestLines: routeEditing.manifestLines
          .filter((item: manifestLine) => item.id !== manifestLine.id)
          .map((item: manifestLine, index: number) => {
            return {
              ...item,
              position: index,
            };
          }),
      };
      setRouteEditing(route);
      handleLoadTempETAData(route);
    }
  };

  const mergeETAWithRoute = (
    route: manifest,
    etaData: manifestDetailETATime
  ) => {
    return {
      ...route,
      ...etaData,
      manifestLines: route.manifestLines.map((mfl: manifestLine) => {
        const newETATime = etaData.manifestLine.find(
          (etaTime) => mfl.id === etaTime.id
        );
        return {
          ...mfl,
          ...newETATime,
        };
      }),
    };
  };

  // Refetch temp ETA
  const handleLoadTempETAData = async (route: manifest) => {
    setIsManifestETALoading(true);
    getManifestDetailTimeETA([route])
      .then((res: any) => {
        setIsManifestETALoading(false);
        const resData = res[0] as manifestDetailETATime;
        if (resData) {
          setRouteEditing(mergeETAWithRoute({ ...route }, resData));
        }
      })
      .catch(() => {
        setIsManifestETALoading(false);
      });
  };

  const handleChangePositionManifestLine = (route: manifest) => {
    setRouteEditing(route);
    handleLoadTempETAData(route);
  };

  return (
    <LayoutPartials.BodyContent
      pageTitle={
        <div className="flex items-center">
          <div>{passedData?.route?.name || routeDetail?.route?.name}</div>
          {Boolean(routeDetail?.status) &&
            routeDetail?.status !== DeliveryStatusValue.PENDING && (
              <DeliveryStatusLabel
                status={routeDetail?.status}
                className="ml-2"
              />
            )}
          <div className="flex-1 border-l border-neutral-20 pl-5 ml-5">
            <div className="flex items-center">
              <div className="text-neutral-70 text-sm">
                <div className="text-xs text-neutral-50">Start time</div>
                {timeFormat(routeDetail?.driver?.workStartTime)}
              </div>
              <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
              <div className="text-neutral-70 text-sm">
                <div className="text-xs text-neutral-50">End time</div>
                {timeFormat(routeDetail?.driver?.workEndTime)}
              </div>

              {routeDetail && (
                <div className="text-neutral-70 text-sm ml-10">
                  <div className="text-xs text-neutral-50">
                    Driver break period
                  </div>
                  {`${timeFormat(
                    routeDetail.driver.breakStartTime
                  )} - ${timeFormat(routeDetail.driver.breakEndTime)}`}
                </div>
              )}
            </div>
          </div>
        </div>
      }
      onTitleBack={() =>
        isEditing ? setIsOpenBackInformModal(true) : handleBack()
      }
      disabledBackButton={editIsSaving || isManifestETALoading}
      rightContent={
        <>
          {routeDetail &&
            DeliveryStatusValue.DELIVERING === routeDetail.status && (
              <>
                <Common.Button
                  outline
                  color="red"
                  className="ml-2"
                  onClick={() => forceStopDelivering.mutate(routeDetail.id)}
                  isLoading={stopIsForcing}
                  disabled={stopIsForcing}
                >
                  Force stop
                </Common.Button>
              </>
            )}
          {routeDetail &&
            Boolean(routeDetail.manifestLines.length) &&
            CanModifyRouteByStatus.includes(routeDetail.status) && (
              <>
                {!isEditing ? (
                  <Common.Button
                    onClick={handleEnableEdit}
                    outline
                    disabled={isManifestETALoading}
                    iconLeft={Icon.Edit}
                  >
                    Edit
                  </Common.Button>
                ) : (
                  <>
                    <Common.Button
                      onClick={handleCancel}
                      color="transparent"
                      disabled={editIsSaving}
                    >
                      Cancel
                    </Common.Button>
                    <Common.Button
                      onClick={handleSaveEditing}
                      className="ml-2"
                      isLoading={editIsSaving}
                      disabled={
                        !isDirty || editIsSaving || isManifestETALoading
                      }
                    >
                      Save
                    </Common.Button>
                  </>
                )}
              </>
            )}
        </>
      }
    >
      <div className="flex h-[calc(100vh-12.75rem)]">
        <div
          className={clsx(
            "w-[28rem] mr-4 bg-neutral-5 relative"
            // isHideDetails && "hidden"
          )}
        >
          {editIsSaving && (
            <div className="absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-30 flex justify-center items-center rounded">
              <Icon.Loading size="28" />
            </div>
          )}
          {routeDetail ? (
            <>
              <div className="flex items-center p-4 pb-6">
                <div className="text-hd5 font-semibold flex-1">
                  {destinationNumber} Destination
                  {destinationNumber > 1 && "s"}
                </div>
              </div>
              <div className="h-[calc(100%-4.75rem)]">
                <CustomScrollbar>
                  {routeDetail.manifestLines.length ? (
                    <>
                      {isEditing ? (
                        <DeliveryRouteDetailEditingList
                          onListChange={handleChangePositionManifestLine}
                          onUnallocate={handleUnallocate}
                          data={routeEditing}
                          // onMoveItem={handleMoveManifestLine}
                          manifestETALoading={isManifestETALoading}
                        />
                      ) : (
                        <DeliveryRouteDetailList
                          routeInfo={routeDetail}
                          data={routeDetail?.manifestLines || []}
                          manifestETALoading={isManifestETALoading}
                        />
                      )}
                    </>
                  ) : (
                    <Common.NoData
                      title="No order found in this delivery manifest."
                      bottomElement={
                        <Common.Button
                          className="mt-3"
                          onClick={handleBackNoneData}
                        >
                          Go back to manifest list
                        </Common.Button>
                      }
                    />
                  )}
                </CustomScrollbar>
              </div>
            </>
          ) : isLoading ? (
            <Common.NoData
              iconRender={Icon.Loading}
              title="Route detail is loading...."
            />
          ) : (
            <Common.NoData
              title="Route does not exist."
              bottomElement={
                <Common.Button className="mt-3" onClick={handleBackNoneData}>
                  Go to Delivery Manifest page
                </Common.Button>
              }
            />
          )}
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          <div className="ralative z-0 w-full h-full">
            <GoogleMap
              storageAddress={storageAddress}
              manifests={routeDetail ? [routeDetail] : []}
            />
          </div>
        </div>
      </div>
      <ModalInform
        open={isOpenSaveEditingModal}
        onClose={handleCancelSave}
        title="Are you sure you want to cancel editing?"
        description="It looks like you have made changes to this route. If you cancel now, your changes will not be saved. Would you like to save these changes?"
        type="error"
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button color="transparent" onClick={handleCancelSave}>
              <span className="text-red">Cancel editing</span>
            </Common.Button>
            <Common.Button className="ml-4" onClick={handleSaveEditing}>
              Save changes
            </Common.Button>
          </div>
        }
      />
      <ModalInform
        open={isOpenBackInformModal}
        type="warning"
        title="There are some changes have been made. Are you sure to cancel all changes?"
        onClose={() => setIsOpenBackInformModal(false)}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button
              color="transparent"
              onClick={() => setIsOpenBackInformModal(false)}
            >
              Close
            </Common.Button>
            <Common.Button className="ml-4" onClick={handleBack}>
              Cancel editing
            </Common.Button>
          </div>
        }
      />
    </LayoutPartials.BodyContent>
  );
};

export default DeliveryRouteDetail;
