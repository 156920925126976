import { Images } from "@/assets/images";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import moment from "moment";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
});

export const distanceFormat = (meters: number) => {
  let result = "";
  if (meters > 100) {
    result = Math.ceil((meters / 1000) * 100) / 100 + "km";
  } else {
    result = meters / 1000 + "km";
  }

  return result;
};

// export const timeFormat = (time?: string | null, alwayReturn?: boolean) => {
//   // alwayReturn to accept format "00:00:00" value
//   if (alwayReturn) {
//     return moment(time).format(TIME_FORMAT.ETA_TIME);
//   } else {
//     return time && !["00:00", "00:00:00"].includes(time)
//       ? moment(time).format(TIME_FORMAT.ETA_TIME)
//       : "--:--";
//   }
// };

export const checkTimeOfWindow = (
  earliest: string,
  latest: string,
  ETATime: string
) =>
  moment(ETATime, ["HH:mmA"]) < moment(earliest, ["HH:mm:A"]) ||
  moment(ETATime, ["HH:mmA"]) > moment(latest, ["HH:mmA"]);

export const timeFormat = (time?: string | null, alwayReturn?: boolean) => {
  // alwayReturn to accept format "00:00:00" value
  if (alwayReturn) {
    return moment(time, ["HH:mm:ss"]).format(TIME_FORMAT.ETA_TIME);
  } else {
    return time && !["00:00", "00:00:00"].includes(time)
      ? moment(time, ["HH:mm:ss"]).format(TIME_FORMAT.ETA_TIME)
      : "--:--";
  }
};

export const timeStopFormat = (time: number) => {
  if (time / 60 >= 1) {
    const hour = Math.ceil(time / 60);
    const minute = Math.ceil(time % 60);
    if (hour / 24 >= 1) {
      const day = Math.ceil(hour / 24);
      return `${day} day${day > 1 ? "s" : ""} ${hour} hour${
        hour > 1 ? "s" : ""
      } ${minute} min${minute > 1 ? "s" : ""}`;
    }
    return `${hour} hour${hour > 1 ? "s" : ""} ${minute} min${
      minute > 1 ? "s" : ""
    }`;
  } else {
    return `${time} min${time > 1 ? "s" : ""}`;
  }
};

export const timeWorkingFormat = (time: number) => {
  // time is minutes
  const duration = moment.duration(time, "minute");
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();
  if (time === 0) {
    return "0 minute";
  }

  return `
    ${days ? days + " days" : ""}
    ${hours ? hours + " hours" : ""}
    ${minutes ? minutes + " minutes" : ""}
  `;
};

export const timeETAFormat = (time: number) => {
  return moment(time).format("hh:mm");
};

export const addressGenerate = (
  addLine1?: null | string,
  addLine2?: null | string,
  suburb?: null | string,
  state?: null | string,
  postCode?: null | string,
  country?: null | string
) =>
  `${addLine1 || ""}, ${addLine2 ? addLine2 + ", " : ""} ${suburb || ""} ${
    state || ""
  } ${postCode || ""}, ${country || "Australia"}`;

export const imgUrl = (url: string, type?: "avatar" | "img") => {
  const img =
    type === "img"
      ? Images.noImg
      : type === "avatar"
      ? Images.noAvatar
      : Images.noImage;
  const firstLetter = url && url.charAt(0);

  return firstLetter === "/" || url == null || !url ? img : url;
};

export const addSpaceBeforeUppercaseChars = (txt: string) => {
  return txt.replace(/([A-Z])/g, " $1").trim();
};

export const getQueryString = () => {
  return `?${window.location.href.split("?")[1]}`;
};

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const dateScheduleFormat = (date: string) => {
  return moment(date).format("DD/MM/YYYY hh:mm:ss A");
};
