import { Icon } from "@/components";
import { useEffect } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

interface Props {
  isFirst?: boolean;
  className?: string;
}

export const DestinationItem = ({ isFirst, className }: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <div className={clsx("px-3 py-2 flex", className)}>
      <div>
        <div className="w-5 h-5 rounded-full bg-neutral-10 text-neutral-50 font-semibold flex items-center justify-center">
          9
        </div>
      </div>
      <div className="flex-1 ml-4">
        <div className="font-semibold">
          <span className="text-blue">#1234</span> - Backyard Bowls
        </div>
        {!isFirst && (
          <div className="flex justify-between mt-2">
            <div className="flex items-center">
              <div data-tip="Earliest time - Latest time">
                <Icon.DeliveryTime size="12" className="fill-neutral-40" />
              </div>
              <span className="ml-1.5 text-neutral-70">7:15AM - 7:45AM</span>
            </div>
            <div className="flex items-center">
              <div data-tip="Stop time">
                <Icon.Stock size="12" className="fill-neutral-40" />
              </div>
              <span className="ml-1.5 text-neutral-70">10 mins</span>
            </div>
            <div className="flex items-center">
              <div className="Estimated time of arrival">
                <Icon.Clock size="12" className="fill-blue-80" />
              </div>
              <span className="ml-1.5 text-blue-80">7:45AM</span>
            </div>
          </div>
        )}
        <div className="flex mt-2">
          {isFirst ? (
            <div className="mt-1" datat-tip="Ditribution center address">
              <Icon.Shop size="12" className="fill-neutral-40" />
            </div>
          ) : (
            <div className="mt-1" data-tip="Delivery address">
              <Icon.Location size="12" className="fill-neutral-40" />
            </div>
          )}
          <div className="ml-1.5 text-neutral-70">
            166 Balnarring Road Merricks north, VIC, 3199, Australia
          </div>
        </div>
      </div>
    </div>
  );
};
