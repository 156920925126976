import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";

import { Common, Icon } from "@/components";

const CustomEditDayScheduleInput = ({
  value = 0,
  isActivated = false,
  onChangeInput,
  onIncDecButton,
  errorMsg = "",
}: {
  value?: number;
  isActivated?: boolean;
  onChangeInput: (value: number) => void;
  onIncDecButton: (type: "inc" | "dec") => void;
  errorMsg?: string;
}) => {
  const [number, setNumber] = useState<number>(+value);

  useEffect(() => {
    setNumber(+value);
  }, [value]);

  useEffect(() => {
    onChangeInput(number);
  }, [number]);

  const isError = errorMsg !== "";

  const haneldIncDec = (type: "inc" | "dec") => {
    if (isActivated) onIncDecButton(type);
  };

  const handleChangeNumber = (value: string) => {
    if (value.length < 6) setNumber(+value);
  };

  return (
    <div
      className={clsx(
        `w-42 flex justify-between items-center rounded border border-neutral-20 bg-white hover:bg-white relative ${
          isError ? "border-red" : ""
        }`
      )}
    >
      <div
        className={clsx(
          `flex-none h-8 flex justify-between items-center px-2 cursor-pointer ${
            isActivated ? "" : "bg-neutral-20 "
          }`
        )}
        onClick={() => {
          haneldIncDec("dec");
        }}
      >
        <Icon.MinusBorder
          iconType="fill"
          className={`${isActivated ? "fill-neutral-50" : "fill-neutral-30"}`}
        />
      </div>
      <Common.Input
        value={number}
        className="h-8 text-center border-0 !px-0 font-medium rounded-none"
        wrapClassName="flex-1"
        disabled={!isActivated}
        onChange={(e) => handleChangeNumber(e.target.value)}
        isError={true}
      />
      <div
        className={clsx(
          `flex-none h-8 flex justify-between items-center px-2 cursor-pointer ${
            isActivated ? "" : "bg-neutral-20 "
          }`
        )}
        onClick={() => {
          haneldIncDec("inc");
        }}
      >
        <Icon.PlusBorder
          iconType="fill"
          className={`${isActivated ? "fill-neutral-50" : "fill-neutral-30"}`}
        />
      </div>
      {isError && (
        <div
          className="absolute right-8 cursor-pointer"
          data-tip
          data-for="errorday"
        >
          <Icon.WarningCircle className="fill-red" />
        </div>
      )}
      {errorMsg && (
        <ReactTooltip
          id="errorday"
          place="bottom"
          type="error"
          className="px-4 py-2"
        >
          {errorMsg}
        </ReactTooltip>
      )}
    </div>
  );
};

export default CustomEditDayScheduleInput;
