import { ModalContent, Card, Common, Icon } from "@/components";
import { RouteOptimizeItem } from "./RouteOptimizeItem";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ModalOptimize = ({ open, onClose, onSubmit }: Props) => {
  return (
    <ModalContent open={open} onClose={onClose}>
      <Card title="Please input optimizing parameters">
        <div className="p-4 max-h-[80vh] overflow-y-auto">
          <div className="font-semibold">3 Selected Routes</div>
          <RouteOptimizeItem />
          <RouteOptimizeItem />
          <RouteOptimizeItem />
          <RouteOptimizeItem />
          <RouteOptimizeItem />
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 items-center">
          <div className="flex-1 flex mr-4">
            <div className="mt-1">
              <Icon.InfoFill size="16" className="fill-red" />
            </div>
            <div className="text-red ml-2">
              You need have at least 1 route to optimize
            </div>
          </div>
          <Common.Button onClick={onClose} color="transparent">
            Cancel
          </Common.Button>
          <Common.Button onClick={onSubmit}>Optimize</Common.Button>
        </div>
      </Card>
    </ModalContent>
  );
};
