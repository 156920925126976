import axios from "axios";
import { useMutation } from "react-query";
import { manifest } from "../models";
import {
  mergeETAData,
  mergeETAIntoRoute,
  mergeETARouteData,
  sortManifestLine,
} from "../utils";

const API_PATH = "Delivery/";

let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

const generateNewCancelTokenSource = () => {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
};

export const useGetManifestsETA = () =>
  useMutation((manifests: manifest[]) => {
    (window as any).controller = new AbortController();
    const signal = (window as any).controller.signal;
    return axios
      .post(
        `${API_PATH}GetManifestsETA`,
        {
          manifests,
        },
        {
          signal,
        }
      )
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response));
  });

export const getManifestDetailTimeETA = async (manifests: manifest[]) => {
  const results = await Promise.all(
    manifests.map((manifest) => {
      if (manifest) {
        return axios
          .post(
            `${API_PATH}GetManifestsETA`,
            {
              manifests: [manifest],
            },
            {
              cancelToken: CANCEL_TOKEN_SOURCE.token,
            }
          )
          .then((res) => {
            if (res.data.errorMessage) {
              return res.data;
            }
            return res.data.data[0];
          })
          .catch((reason) => {
            return Promise.reject(reason);
          });
      }
      return null;
    })
  );
  return results;
};

export const getManifestDetailRouteETA = async (manifests: manifest[]) => {
  const results = await Promise.all(
    manifests.map((manifest) => {
      if (manifest) {
        return axios
          .post(`${API_PATH}GetManifestsETA`, {
            manifests: [manifest],
          })
          .then((res) => {
            if (res.data.errorMessage) {
              return res.data;
            }
            return res.data.data[0];
          })
          .catch((reason) => {
            return Promise.reject(reason);
          });
      }
      return null;
    })
  );
  return results;
};

export const useGetRouteById = () =>
  useMutation((id: string) =>
    axios
      .post(`${API_PATH}GetManifestDetail`, {
        id,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data.map((manifest: manifest) => {
          return sortManifestLine(manifest);
        });
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useLoadDoc = () =>
  useMutation((data: { docId: string; accountId: string }) =>
    axios
      .post(`${API_PATH}LoadDoc`, {
        docId: data.docId,
        accountId: data.accountId,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useSaveEditingRoutes = () =>
  useMutation((manifests: manifest[]) =>
    axios
      .post(`${API_PATH}SaveManifests`, {
        manifests,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useForceStopDelivering = () =>
  useMutation((manifestId: string) =>
    axios
      .post(`${API_PATH}StopDelivering`, {
        manifestId,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetUnallocatedList = () =>
  useMutation((date: string) =>
    axios
      .post(`${API_PATH}GetUnallocatedDocument`, {
        date,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res?.data?.data || [];
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetManifestDetailByRoute = () =>
  useMutation((data: { date: string; routeId: string }) =>
    axios
      .post(`${API_PATH}GetManifestDetailByRoute`, {
        ...data,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data.map((manifest: manifest) => {
          return sortManifestLine(manifest);
        });
      })
      .catch((reason) => Promise.reject(reason.response))
  );

// Fetch manifests in efit screen
export const getRouteByIds = async (ids: string[]) => {
  const results = await Promise.all(
    ids.map((id: string) => {
      return axios
        .post(`${API_PATH}GetManifestDetail`, {
          id,
        })
        .then((res) => {
          if (res.data.errorMessage) {
            return res.data;
          }

          return res.data.data.map((manifest: manifest) => {
            return sortManifestLine(manifest);
          })[0];
        })
        .catch((reason) => Promise.reject(reason.response));
    })
  );
  return results;
};

export const getRouteById = (id: string) =>
  axios
    .post(`${API_PATH}GetManifestDetail`, {
      id,
    })
    .then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }

      return res.data.data.map((manifest: manifest) => {
        return sortManifestLine(manifest);
      })[0];
    })
    .catch((reason) => Promise.reject(reason.response));

export const getManifestDetailETAById = (ids: string[]) =>
  axios
    .post(
      `${API_PATH}GetManifestDetailETA`,
      {
        ids,
      },
      {
        cancelToken: CANCEL_TOKEN_SOURCE.token,
      }
    )
    .then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }
      return res.data.data;
    });

export const getManifestDetailMapById = (ids: string[]) =>
  axios
    .post(
      `${API_PATH}GetManifestDetailMap`,
      {
        ids,
      },
      {
        cancelToken: CANCEL_TOKEN_SOURCE.token,
      }
    )
    .then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }
      return res.data.data;
    });
export const getRouteETAWithMap = async (route: manifest) => {
  const result = await Promise.all([
    getManifestDetailETAById([route.id]),
    getManifestDetailMapById([route.id]),
  ]);

  const etsMerged = mergeETARouteData(result);
  return mergeETAIntoRoute(route, etsMerged) as manifest;
};

export const getRouteDetailById = async (routeId: string) => {
  const results = await Promise.all([
    getRouteById(routeId),
    getManifestDetailETAById([routeId]),
    getManifestDetailMapById([routeId]),
  ]);
  const etsMerged = mergeETARouteData([results[1][0], results[2][0]]);
  return mergeETAIntoRoute(results[0], etsMerged) as manifest;
};

export const getRouteETAWithMapIds = async (ids: string[]) => {
  const result = await Promise.all([
    getManifestDetailETAById(ids),
    getManifestDetailMapById(ids),
  ]);

  return mergeETAData(result);
};

export const getETAsGroup = (idGroup: any[]) =>
  axios
    .all(idGroup.map((ids: string[]) => getRouteETAWithMapIds(ids)))
    .then((res) => console.log("res: ", res));

export const finishPendingRequests = (cancellationReason: string) => {
  CANCEL_TOKEN_SOURCE.cancel(cancellationReason);
  generateNewCancelTokenSource();
};
