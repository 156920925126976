import { useEffect } from "react";
import { Icon, Common } from "@/components";
import ReactTooltip from "react-tooltip";

export const RouteOptimizeItem = () => {
  // const [startTime, setStartTime] = useState<Date | null>(null);
  // const [endTime, setEndTime] = useState<Date | null>(null);

  // const handleChange = (target: string, date: Date | null) => {

  // };

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <div className="p-4 border border-neutral-20 rounded mt-3">
      <div className="flex pb-4 border-b border-neutral-10 space-x-3">
        <div className="flex-1 flex items-center">
          <Common.DotColor />
          <span className="font-semibold ml-2 mr-1.5">
            South to West - Route AB
          </span>
          <span>( 12 orders )</span>
        </div>
        <div className="flex-1 flex items-center">
          <Icon.Driver size="12" className="fill-neutral-40" />
          <span className="text-neutral-70 ml-2">Arlene McCoy</span>
          <button className="p-2 ml-auto">
            <Icon.AngleUp size="12" className="stroke-blue" />
          </button>
        </div>
      </div>
      <div className="flex pt-4 space-x-3">
        <div className="flex-1">
          {/* <Common.TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            onChange={handleChange}
            wrapClassName="mb-4"
            label="Working time"
          /> */}
          <Common.Checkbox id="wt" label="Return to depot after delivery" />
        </div>
        <div className="flex-1">
          {/* <Common.TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            onChange={handleChange}
            wrapClassName="mb-4"
            label="Driver break time"
          /> */}
          <div className="flex items-center">
            <Common.Checkbox id="dbt" label="Combine this route" />
            <span
              className="ml-1.5"
              data-tip="This will only combine this run for today"
            >
              <Icon.InfoFill size="12" className="fill-neutral-40" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
