import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import * as yup from "yup";
import {
  ModalContent,
  Card,
  Common,
  Icon,
  DeliveryStatusLabel,
} from "@/components";
import {
  CanModifyRouteByStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import { manifest, manifestLine } from "../models";
import { useFormik } from "formik";

const validationSchema = yup.object().shape({
  position: yup
    .number()
    .min(1, "Position must be greater than or equal to 1.")
    .required("Please enter position number.")
    .typeError("Position is a number!"),
});

const RouteItem = ({
  isCurrent,
  isActive,
  data,
  disable,
  onClick,
}: {
  isCurrent?: boolean;
  isActive?: boolean;
  disable?: boolean;
  data: manifest;
  onClick: () => void;
}) => {
  const isNoAction = isCurrent || disable || isActive;
  return (
    <div
      className={clsx(
        "text-sm flex items-center mb-0.5 p-3",
        !isNoAction && "hover:bg-neutral-10 cursor-pointer",
        isCurrent && "bg-neutral-20",
        isActive && "bg-neutral-10",
        disable && "bg-neutral-5"
      )}
      onClick={() => (isNoAction ? {} : onClick())}
    >
      <div>
        <Common.DotColor />
      </div>
      <span className="font-semibold mx-1.5 line-clamp-1">
        {data.route.name}
      </span>
      {data.status !== DeliveryStatusValue.PENDING && (
        <DeliveryStatusLabel status={data.status} className="mr-2" />
      )}
      {isCurrent && (
        <span className="text-neutral-50 whitespace-nowrap mr-2">
          ( Current )
        </span>
      )}
      {isActive && (
        <div className="ml-auto">
          <Icon.Checked size="14" className="fill-blue" />
        </div>
      )}
    </div>
  );
};

interface Props {
  open: boolean;
  manifestData: manifest;
  manifests: manifest[];
  manifestLineData?: manifestLine;
  onClose: () => void;
  onMoveItem: (manifest?: manifest, position?: string) => void;
}

export const ModalMoveRouteEditScreen = ({
  open,
  manifestData,
  manifests,
  manifestLineData,
  onClose,
  onMoveItem,
}: Props) => {
  const [isRouteSelected, setRouteSelected] = useState<manifest>();
  const [searchKeyword, setSearchKeyword] = useState("");

  const formik = useFormik({
    initialValues: {
      position: 1,
    },
    validationSchema,
    onSubmit: (values) => {
      onMoveItem(isRouteSelected, values.position.toString());
    },
  });

  const results = useMemo<manifest[]>(() => {
    return manifests
      ? manifests.filter((item: manifest) => {
          if (
            item.route.name &&
            item.route.name.toUpperCase().indexOf(searchKeyword.toUpperCase()) >
              -1
          )
            return true;
          return false;
        })
      : [];
  }, [searchKeyword, manifests]);

  const handleSelectRoute = (route: manifest) => {
    setRouteSelected(route);
    const position = route.manifestLines.length + 1;
    formik.setFieldValue("position", position);
  };

  const handleClose = () => {
    setRouteSelected(undefined);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setRouteSelected(undefined);
    }
  }, [open]);

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[31.25rem] w-full"
    >
      <Card
        title={`Move order #${manifestLineData?.document?.saleDocumentNumber} to...`}
      >
        <div className="p-4">
          <Common.Input
            iconRight={Icon.Search}
            iconType="stroke"
            placeholder="Search route name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onClear={() => setSearchKeyword("")}
          />
          <div className="h-52 overflow-y-auto mt-3">
            {manifests && manifests.length ? (
              <>
                {results.length ? (
                  results.map((route) => {
                    const disabled = !CanModifyRouteByStatus.includes(
                      route.status
                    );
                    return (
                      <RouteItem
                        key={route.id}
                        data={route}
                        onClick={() => handleSelectRoute(route)}
                        isCurrent={manifestData?.id === route.id}
                        isActive={isRouteSelected?.id === route.id}
                        disable={disabled}
                      />
                    );
                  })
                ) : (
                  <Common.NoData title={"No Route founded"} />
                )}
              </>
            ) : (
              <Common.NoData title={"No Route data!"} />
            )}
          </div>
          <div className="border-t border-neutral-10 mt-3 pt-4 mb-2">
            <Common.Input
              label={`Change position order #${
                manifestLineData?.document?.saleDocumentNumber
              }${
                isRouteSelected
                  ? ` in "${isRouteSelected?.route?.name}" route`
                  : ""
              }.`}
              disabled={!isRouteSelected?.id}
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              isError={
                formik.touched.position && Boolean(formik.errors.position)
              }
              errorMessage={
                formik.touched.position ? formik.errors.position : ""
              }
              onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
            />
          </div>
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
          <Common.Button onClick={handleClose} color="transparent">
            Cancel
          </Common.Button>
          <Common.Button
            disabled={Boolean(!isRouteSelected?.id)}
            onClick={() => formik.handleSubmit()}
          >
            Move
          </Common.Button>
        </div>
      </Card>
    </ModalContent>
  );
};
