import { useEffect, useState } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { Common, Icon, ModalInform } from "@/components";
import { SnackbarUtils } from "@/utils";
import { OptimizingItem } from "./OptimizingItem";
import { ModalOptimized } from "./ModalOptimized";

interface Props {
  isFirst?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  className?: string;
  onOptimizedCompleted: () => void;
}

export const OptimizingGroup = ({
  isFirst,
  className,
  isWarning,
  isSuccess,
  onOptimizedCompleted,
}: Props) => {
  const isOptimizing = !isSuccess && !isWarning;
  const [isOpenOptimizedModal, setOpenOptimizedModal] = useState(false);
  const [isOpenDiscardModal, setOpenDiscardModal] = useState(false);

  useEffect(() => {
    if (isWarning) {
      SnackbarUtils.warning(
        "Don't have enough route to deliver all orders. <span style='color: var(--color-yellow); font-weight: 600;'>2 unallocated orders</span> is waiting for allocating."
      );
      ReactTooltip.rebuild();
      return () => {
        ReactTooltip.hide();
      };
    }
  }, [isWarning]);

  const handleDiscardOptimized = () => {
    setOpenOptimizedModal(false);
    setOpenDiscardModal(true);
  };

  const handleCancelDiscard = () => {
    setOpenDiscardModal(false);
    setOpenOptimizedModal(true);
  };
  const handleDiscard = () => {
    setOpenDiscardModal(false);
  };

  return (
    <>
      <div
        className={clsx(
          "py-3 px-2 rounded bg-blue-10",
          !isFirst && "mt-2.5",
          isWarning && "!bg-status-bg-warning",
          isSuccess && "!bg-status-bg-success",
          className
        )}
      >
        <div className="flex items-center min-h-[1.75rem] mb-2">
          <div className="text-sm font-semibold mr-2">
            Group 1: 3 routes optimizing
          </div>
          {isWarning && (
            <div data-tip="Don't have enough route to deliver all orders. 2 unallocated orders is waiting for allocating.">
              <Icon.InfoFill size="16" className="fill-yellow" />
            </div>
          )}
          {!isOptimizing && (
            <Common.Button
              outline
              btSize="sm"
              className="ml-auto"
              onClick={() => setOpenOptimizedModal(true)}
            >
              Preview and Apply
            </Common.Button>
          )}
        </div>
        <OptimizingItem isFirst isOptimizing={isOptimizing} />
        <OptimizingItem isOptimizing={isOptimizing} />
        <OptimizingItem isOptimizing={isOptimizing} />
      </div>
      <ModalOptimized
        open={isOpenOptimizedModal}
        onClose={() => setOpenOptimizedModal(false)}
        onDiscard={handleDiscardOptimized}
        onSubmit={onOptimizedCompleted}
      />
      <ModalInform
        open={isOpenDiscardModal}
        onClose={handleCancelDiscard}
        onConfirm={handleDiscard}
        confirmBtnText="Discard"
        title="Are you sure you want to discard 3 routes in Group 1?"
        description="If you discard now, 3 routes will not be saved."
        type="error"
      />
    </>
  );
};
